import React, { useState, useEffect } from "react";
import SubLogin from "./Sub/SubLogin";
import SubCompany from "./Sub/SubCompany";
import { InvBlue } from "../Common";
import { withCookies } from "react-cookie";
import {
  SetPassword,
  RequestToken
} from "../../src/react-lib/apis/issara/apps/TRK/INVmanual";
import { Input, Button, Icon, Dimmer, Loader, Modal } from "semantic-ui-react";
import { TRKVerifyEmployee } from "../../src/react-lib/apis/issara/apps/TRK/INVUser";
import ErrorMessage from "../react-lib/apps/common/ErrorMessage";

export default withCookies((props: any) => {
  const [sub, setSub] = useState("Login");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [registerEmployeeNumber, setRegisterEmployeeNumber] = useState("");
  const [registerUsername, setRegisterUsername] = useState("");

  const [apiToken, setApiToken] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const [modalLoginMessage, setModalLoginMessage] = useState(false);
  const [checkInfoError, setCheckInfoError] = useState<any>(null);
  const [checkInfoLoading, setCheckInfoLoading] = useState(false);

  const viewData = {
    SubLogin: {
      Item12: username,
      Item30: password
    }
  };
  
  useEffect(() => {
    console.log(props.cookies.get("hostName"))
    if(!props.cookies.get("hostName") || props.cookies.get("hostName") === undefined){
      setSub("Company")
    }
  }, [])

  const handleEvent = async ({
    message,
    params
  }: {
    message: string;
    params: any;
  }) => {
    console.log("handleEvent", message, params.name);
    if (message === "inputChange" && params.name === "Item12") {
      setUsername(params.value);
    } else if (message === "inputChange" && params.name === "Item30") {
      setPassword(params.value);
    } else if (message === "clickButton" && params.name === "Item16") {
      // Login

      if (username === "") {
        setLoginMessage("กรุณาระบุ telephone no.");
        setModalLoginMessage(true);
        return;
      }
      if (password === "") {
        setLoginMessage("กรุณาระบุ password");
        setModalLoginMessage(true);
        return;
      }

      const mobNative =
      typeof (window as any).MobNative !== "undefined"
        ? (window as any).MobNative
        : null;

      let fcmToken = ""
      let deviceType = ""
      let deviceId = ""
      if (mobNative) {
        fcmToken = mobNative.getFcmToken()
        deviceType = mobNative.getDeviceType()
        deviceId = mobNative.getDeviceId()
        mobNative.debug(fcmToken + " " + deviceType + " " + deviceId)
      } 

      const [response, error, network] = await RequestToken.post({
        data: {
          username: username,
          password: password,
          application: "invtracking",
          ...(deviceType && {device_type: deviceType}),
          ...(deviceId && {device_name: deviceId}),
          ...(fcmToken && {device_token: fcmToken})
        }
      });
      if (error) {
        console.log(error, network);
        if (error.hasOwnProperty("non_field_errors")) {
          setLoginMessage(
            "telephone no. หรือ password ไม่ถูกต้อง \nกรุณาลองใหม่อีกครั้ง\n" +
            "status" + network?.status + "\n" +
            "responsText" + network?.responseText + "\n" 
          );

          setModalLoginMessage(true);
          return;
        }

        if (network) {
          setLoginMessage("เกิดข้อผิดพลาดทาง Network");
          setModalLoginMessage(true);
          return;
        }
      }
      console.log("CardLogin response.token ", response.token)
      // If succeed, then set token
      console.log(response);
      props.onEvent({
        message: "login",
        params: {
          value: response.token
        }
      });
    } else if (message === "Login") {
      setSub("Login");
    } else if (message === "clickButton" && params.name === "register") {
      setSub("Register");
    } else if(message === "clickButton" && params.name === "company"){
      setSub("Company")
    }else if (message === "clickButton" && params.name === "checkinfo") {
      console.log(params.value);
      setRegisterEmployeeNumber(params.value.employeeNumber);
      setRegisterUsername(params.value.username);
      setCheckInfoLoading(true);
      setCheckInfoError(null);
      const [response, error, network] = await TRKVerifyEmployee.list({
        params: {
          username: params.value.username,
          employee_number: params.value.employeeNumber
        }
      });
      setCheckInfoLoading(false);
      if (!error && response.items.length > 0) {
        console.log(response);
        setApiToken(response.items[0].api_token);
        setSub("CreatePassword");
      } else {
        if (error.length > 200) {
          setCheckInfoError(
            `${network.response.status}: ${network.response.statusText}`
          );
        } else {
          setCheckInfoError(error);
        }
        console.log("verify failed");
      }
    } else if (message === "clickButton" && params.name === "setpassword") {
      console.log(params.value);
      const [response, error, network] = await SetPassword.post({
        data: {
          password: params.value.password,
          confirm_password: params.value.confirmPassword
        },
        apiToken: apiToken
      });
      if (!error) {
        console.log(response);
        setSub("RegisterSuccess");
      } else {
        console.log(error);
        console.log(network);
      }
    } else if (message === "clickButton" && params.name === "continue") {
      setSub("Login");
    } else {
      props.onEvent({ message, params });
    }
  };
  
  return (
    <div style={{ height: "100%" }}>
      {sub === "Company" && (
        <SubCompany 
          onEvent={handleEvent}
          onHostnameChange={props.onHostnameChange}
        />
      )}
      {sub === "Login" ? (
        <SubLogin viewData={viewData.SubLogin} onEvent={handleEvent} />
      ) : sub === "Register" ? (
        <Register
          viewData={viewData.SubLogin}
          onEvent={handleEvent}
          r
          registerEmployeeNumber={registerEmployeeNumber}
          registerUsername={registerUsername}
          error={checkInfoError}
          loading={checkInfoLoading}
        />
      ) : sub === "CreatePassword" ? (
        <CreatePassword viewData={viewData.SubLogin} onEvent={handleEvent} />
      ) : sub === "RegisterSuccess" ? (
        <RegisterSuccess viewData={viewData.SubLogin} onEvent={handleEvent} />
      ) : (
        <></>
      )}
      <Modal
        style={{ maxHeight: "25vh" }}
        open={modalLoginMessage}
        onClose={() => {
          setModalLoginMessage(false);
          setLoginMessage("");
        }}
      >
        <Modal.Description>
          <h3 style={{ paddingTop: "20px", textAlign: "center" }}>
            มีข้อผิดพลาดในการเข้าสู่ระบบ
          </h3>
          <div style={{ textAlign: "center", lineHeight: "20px" }}>
            {loginMessage.split("\n").length > 1 ? (
              <div>
                <span>{loginMessage.split("\n")[0]}</span>
                <br />
                <span>{loginMessage.split("\n")[1]}</span>
              </div>
            ) : (
              <span>{loginMessage}</span>
            )}
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "15px",
              paddingBottom: "15px"
            }}
          >
            <Button
              color="green"
              onClick={() => {
                setModalLoginMessage(false);
                setLoginMessage("");
              }}
            >
              ตกลง
            </Button>
          </div>
        </Modal.Description>
      </Modal>
    </div>
  );
});

const Register = (props: any) => {
  const [username, setUsername] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");

  useEffect(() => {
    setUsername(props.registerUsername);
    setEmployeeNumber(props.registerEmployeeNumber);
  }, [props.registerUsername, props.registerEmployeeNumber]);
  return (
    <div
      className="mob-login"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#247DAF",
          color: "white",
          textAlign: "center",
          height: "59px"
        }}
      >
        ลงทะเบียน
      </div>

      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "59px",
          width: "59px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#247DAF",
          color: "white"
        }}
        onClick={e => props.onEvent({ message: "Login", params: {} })}
      >
        <Icon size="large" name="angle left" />
      </div>
      <Dimmer active={props.loading} inverted>
        <Loader inverted />
      </Dimmer>
      <div style={{ margin: "10px", color: "#A8AAB7", fontSize: "20px" }}>
        กรุณาระบุข้อมูลเพื่อยืนยันตัวตน
      </div>

      {/* <ErrorMessage error={props.error} style={{ margin: 0 }} /> */}
      <div style={{ width: "90%" }}>
        <div style={{ margin: "10px", color: "#666666", fontSize: "20px" }}>
          ชื่อผู้ใช้ / เบอร์มือถือ<span style={{ color: "red" }}>*</span>:
        </div>
        <Input
          style={{ width: "100%" }}
          value={username}
          onChange={(e, v) => setUsername(v.value)}
        />
        <div style={{ margin: "10px", color: "#666666", fontSize: "20px" }}>
          รหัสพนักงาน<span style={{ color: "red" }}>*</span>:
        </div>
        <Input
          style={{ width: "100%" }}
          value={employeeNumber}
          onChange={(e, v) => setEmployeeNumber(v.value)}
        />
        {props.error && (
          <div
            style={{
              color: "red",
              fontSize: "16px",
              textAlign: "center",
              paddingTop: "16px"
            }}
          >
            ไม่พบรายชื่อของท่าน
            <br />
            กรุณาติดต่อหัวหน้าแผนกหรือฝ่ายสารสนเทศ (IT){" "}
          </div>
        )}
      </div>

      <Button
        size="big"
        disabled={!username || !employeeNumber}
        style={{ backgroundColor: "#247DAF", color: "white" }}
        onClick={e =>
          props.onEvent({
            message: "clickButton",
            params: {
              name: "checkinfo",
              value: { username: username, employeeNumber: employeeNumber }
            }
          })
        }
      >
        ตรวจสอบข้อมูล
      </Button>
      <br />
    </div>
  );
};

const CreatePassword = (props: any) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (checkPasswordPolicy(password, confirmPassword)) {
      setVerifyPassword(true);
    } else {
      setVerifyPassword(false);
    }
  }, [password, confirmPassword]);
  const checkPasswordPolicy = (another: string, password: string) => {
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var pass = !!password.match(lowerCaseLetters);
    pass = pass && !!password.match(upperCaseLetters);
    pass = pass && !!password.match(numbers);
    pass = pass && password.length >= 8;

    if (pass && !password.localeCompare(another)) {
      return true;
      // setShowAlertText(false)
    } else {
      return false;
      // setShowAlertText(true)
    }
  };

  return (
    <div
      className="mob-create-pass"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "20px"
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#247DAF",
          color: "white",
          textAlign: "center",
          height: "59px"
        }}
      >
        สร้างรหัสผ่าน
      </div>

      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "59px",
          width: "59px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#247DAF",
          color: "white"
        }}
        onClick={e =>
          props.onEvent({
            message: "clickButton",
            params: { name: "register" }
          })
        }
      >
        <Icon size="large" name="angle left" />
      </div>

      <div style={{ textAlign: "center" }}>
        <div style={{ margin: "10px" }}>กรุณาระบุรหัสผ่าน</div>
        <br />
        <div style={{ color: verifyPassword ? "black" : "red" }}>
          <div style={{ margin: "10px" }}>เป็นตัวอักษรภาษาอังกฤษ</div>
          <div style={{ margin: "10px" }}>ตัวใหญ่ตัวเล็กและตัวเลข</div>
          <div style={{ margin: "10px" }}>ความยาวไม่น้อยกว่า 8 ตัวอักษร</div>
        </div>
      </div>
      <div style={{ width: "90%" }}>
        <div style={{ margin: "10px" }}>
          รหัสผ่าน<span style={{ color: "red" }}>*</span>:
        </div>
        <Input
          style={{ width: "100%" }}
          value={password}
          onChange={(e, v) => setPassword(v.value)}
          type={showPassword ? "text" : "password"}
          icon={
            <Icon
              style={{
                right: "0px",
                left: "auto",
                cursor: "pointer",
                zIndex: 100
              }}
              link
              name={showPassword ? "eye" : "eye slash"}
              color="grey"
              size="large"
              onClick={() => setShowPassword(!showPassword)}
            />
          }
        />
        <div style={{ margin: "10px" }}>
          ยืนยันรหัสผ่าน<span style={{ color: "red" }}>*</span>:
        </div>
        <Input
          style={{ width: "100%" }}
          value={confirmPassword}
          onChange={(e, v) => setConfirmPassword(v.value)}
          type={showConfirmPassword ? "text" : "password"}
          icon={
            <Icon
              style={{
                right: "0px",
                left: "auto",
                cursor: "pointer",
                zIndex: 100
              }}
              link
              name={showConfirmPassword ? "eye" : "eye slash"}
              color="grey"
              size="large"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          }
        />
      </div>
      <Button
        size="big"
        style={{ backgroundColor: "#247DAF", color: "white" }}
        disabled={!verifyPassword}
        onClick={e =>
          props.onEvent({
            message: "clickButton",
            params: {
              name: "setpassword",
              value: { password: password, confirmPassword: confirmPassword }
            }
          })
        }
      >
        ต่อไป
      </Button>
      <div></div>
    </div>
  );
};

const RegisterSuccess = (props: any) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "20px"
    }}
  >
    <div></div>
    <div style={{ textAlign: "center" }}>
      <div style={{ margin: "10px" }}>ลงทะเบียนสำเร็จ</div>
    </div>
    <div style={{ fontSize: "100px", color: "#247DAF" }}>&#10003;</div>
    <Button
      size="big"
      style={{ backgroundColor: "#247DAF", color: "white" }}
      onClick={e =>
        props.onEvent({ message: "clickButton", params: { name: "continue" } })
      }
    >
      กลับสู่หน้าหลัก
    </Button>
    <div></div>
  </div>
);
