import React from "react";
import { Cookies, withCookies } from "react-cookie";
import CardLogin from "./CardLogin";
import CardTaskList from "./CardTaskList";
import CardOtherMenu from "./CardOtherMenu";
import CardNoti from "./CardNoti";
import { ProfileProvider, AuthenProvider } from "./MobContext";
import { SignOut } from "../react-lib/apis/issara/apps/TRK/INVcommon";
import {
  TaskList,
  InvoiceSerializer,
  InvoiceDetail,
} from "../react-lib/apis/issara/apps/TRK/INVInvoice";
import {TaskSerialList} from "../react-lib/apis/issara/apps/TRK/INVInvoiceM";
import { GetTotalInvoice } from "../react-lib/apis/issara/apps/TRK/INVcommon";
import {
  LoginEmployee,
  EmployeeSerializer,
} from "../react-lib/apis/issara/apps/TRK/INVEmployeeExtra";
import TabLayout from "./TabLayout";
import CardProfile from "./CardProfile";
import "../Mob/CardLogin.css";
import "../Mob/CardProfile.css";
import "semantic-ui-css/semantic.min.css";
import { InvNotificationList } from "../react-lib/apis/issara/apps/TRK/INVInvNotification";
import * as ServiceWorker from "../serviceWorker";
import {
  POSITION_CODE,
  COLUMN_TASK_LIST_STATE,
  COLUMN_TEXT_TASK_LIST,
} from "../Common";
import config from "../config/config";
import {
  dateToStringWithoutTimeAD,
  parseDate,
} from "../react-lib/utils/dateUtils";
import moment from "moment";
import { Item } from "semantic-ui-react";
import { isParameter } from "typescript";
import axios, {CancelTokenSource} from 'axios';

const DEFAULT_STATE = {
  employee: null,
  position_code: null,
  viewData: {},
  invoices: [],
  subInvoices: [],
  allForScanInvoices: [],
  data: "",
  selectedGroupStatus: 0,
  activePageList: [],
  alreadySuccessSyncAllInvoice: false,
  completeWholeList: false,
  errorWholeList: "",
  
};

export const limitPageSize = 7;

class MobInv extends React.Component<
  {
    cookies: Cookies;
  },
  {
    login: boolean;
    apiToken: string | null;
    position_code: string | null;
    sub: string;
    viewData: any;
    invoices: InvoiceSerializer[];
    subInvoices: InvoiceSerializer[];
    allForScanInvoices: InvoiceSerializer[];
    alreadySuccessSyncAllInvoice: boolean;
    // selectedInvoice: InvoiceSerializer | null;
    data: any;
    filters: string;
    startDate: Date;
    endDate: Date;
    employee: EmployeeSerializer | null;
    loading: boolean;
    noti: any[];
    hostName: string | null;

    positionKey: string;
    statusList: any;
    selectedGroupStatus: number;
    activePageList: number[];
    completeWholeList: boolean;
    errorWholeList: string;
    
  }
> {
  MobNative: any | null;
  searchJob: any | null;
  source: CancelTokenSource | null;

  constructor(props: any) {
    super(props);
    this.searchJob = null;
    this.MobNative =
      typeof (window as any).MobNative !== "undefined"
        ? (window as any).MobNative
        : null;
    const apiToken = this.getToken();
    var params = new URLSearchParams(window.location.search);
    const reDirectFromCamera = params.get("reDirectFromCamera");
    console.log("redirect", reDirectFromCamera);

    const todayDate = new Date();

    let yesterdayDate = new Date(todayDate);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);


    if (apiToken && apiToken !== "" && reDirectFromCamera) {
      this.state = {
        ...DEFAULT_STATE,
        hostName: props.cookies.get("hostName"),
        login: true,
        apiToken: apiToken,
        sub: "TaskList",
        filters: "",
        employee: null,
        loading: false,
        startDate: yesterdayDate,
        endDate: todayDate,
        noti: [],
        statusList: {},
      };
    } else if (apiToken && apiToken !== "") {
      this.state = {
        ...DEFAULT_STATE,
        hostName: props.cookies.get("hostName"),
        login: true,
        apiToken: apiToken,
        sub: "TaskList",
        filters: "",
        startDate: yesterdayDate,
        endDate: todayDate,
        employee: null,
        loading: false,
        noti: [],
        statusList: {},
      };
    } else {
      this.state = {
        ...DEFAULT_STATE,
        hostName: props.cookies.get("hostName"),
        login: false,
        apiToken: null,
        sub: "Login",
        filters: "",
        startDate: yesterdayDate,
        endDate: todayDate,
        employee: null,
        loading: false,
        noti: [],
        statusList: {},
      };
    }
  }

  componentDidMount = () => {
    // let hostName = this.props.cookies.get("hostName")
    // if( hostName !== window.location.hostname && hostName){
    //   window.location.hostname = hostName
    // }
    this.getData();
    window.addEventListener("softReload", this.softReload.bind(this), false);
    window.addEventListener("logout", this.logout.bind(this), false);
  };
  
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.hostName !== this.state.hostName) {
      config.changeDomain(this.state.hostName);
    }
  }

  softReload() {
    this.getData();
  }

  logout() {
    this.setToken("");
    this.setState({
      login: false,
      sub: "Login",
      filters: "",
      employee: null,
      invoices: [],
      subInvoices: [],
      allForScanInvoices: [],
      alreadySuccessSyncAllInvoice: false,
      selectedGroupStatus: 0,
      activePageList: [],
      statusList: {},
    });
    this.MobNative?.setInvoiceData(JSON.stringify([]));
    ServiceWorker.unregister();
    SignOut.get({ apiToken: this.state.apiToken });
  }

  handleEvent = ({ message, params }: { message: string; params: any }) => {
    console.log("Mob handleEvent", message, params);
    if (message === "setApiToken" && params.value) {
      this.setToken(params.value);
    } else if (message === "login" && params.value) {
      this.setToken(params.value);
      this.getData();
      this.setState({
        sub: "TaskList",
      });
    } else if (message === "Logout") {
      this.logout();
    } else if (message === "clickTab") {
      if (params.name === "Camera") {

        if (!this.state.completeWholeList && !this.state.errorWholeList) {
          console.log("กำลังโหลด ข้อมูล Invoice "+ this.state.statusList?.total +" ใบ \nกรุณารอสักครู่")
          this.MobNative?.alert("กำลังโหลด ข้อมูล Invoice "+ this.state.statusList?.total +" ใบ \nกรุณารอสักครู่ ...")
          // this.setState({ errorWholeList: "" })
          return
        } else if (!this.state.completeWholeList && this.state.errorWholeList) {
          console.log(this.state.errorWholeList)
          this.MobNative?.alert("โหลดไม่สำเร็จ\n" + this.state.errorWholeList + "\nกรุณาลดจำนวนวันให้น้อยกว่า 30 วัน\n โดยให้เลือกช่วงวันที่อื่นๆ ในหน้างานรวม และลองอีกครั้ง")
          // this.setState({ errorWholeList: "" })
          return
        } 

        let isSaleBilling = false;
        if (
          POSITION_CODE.SALE.includes(this.state.position_code || "") ||
          POSITION_CODE.BILLING.includes(this.state.position_code || "")
        ) {
          isSaleBilling = true;
        }
        let isTransportRole = false;
        if (POSITION_CODE.TRANSPORT.includes(this.state.position_code || "")) {
          isTransportRole = true;
        }

        let isSale = false;
        if (POSITION_CODE.SALE.includes(this.state.position_code || "")) {
          isSale = true;
        }

        let appVersion = this.MobNative?.getApplicationVersion().toString().split(".")
        const majorVersion = Number(appVersion.shift())
        const minorVersion = Number(appVersion.pop())
    
        if (majorVersion >= 1 && minorVersion >= 45) {
          // new
          if (!this.state.alreadySuccessSyncAllInvoice) {
            this.MobNative?.setSubInvoice(JSON.stringify(this.state.subInvoices))
          }
        } else {
          // old
          if (!this.state.alreadySuccessSyncAllInvoice) {
            // Workaround not have all invoices
            this.MobNative?.setInvoiceData(JSON.stringify(this.state.subInvoices))
          }
        }
    
        this.MobNative?.launchScan(
          isSaleBilling,
          isTransportRole,
          isSale,
          config.API_HOST
        );
      } else if (params.name === "Noti") {
        this.setState({ sub: params.name });
        // this.MobNative?.launchNoti();
      } else {
        this.setState({ sub: params.name });
      }
    } else if (message === "updateTaskList") {
      this.getTaskList();
    } else if (
      message === "clickButton" &&
      params.name === "CaptureOtherDocs"
    ) {
      console.log("launchShoot", params);

      let isSaleBilling = false;
      if (
        POSITION_CODE.SALE.includes(this.state.position_code || "") ||
        POSITION_CODE.BILLING.includes(this.state.position_code || "")
      ) {
        isSaleBilling = true;
      }

      let isTransportRole = false;
      if (POSITION_CODE.TRANSPORT.includes(this.state.position_code || "")) {
        isTransportRole = true;
      }

      let isSale = false;
      if (POSITION_CODE.SALE.includes(this.state.position_code || "")) {
        isSale = true;
      }

      let appVersion = this.MobNative?.getApplicationVersion().toString().split(".")
      const majorVersion = Number(appVersion.shift())
      const minorVersion = Number(appVersion.pop())

      if (majorVersion >= 1 && minorVersion >= 45) {
        // new
        this.MobNative?.setSubInvoice(JSON.stringify(this.state.subInvoices))
      } else {
        // old 
        if (!this.state.alreadySuccessSyncAllInvoice) {
          this.MobNative?.setInvoiceData(JSON.stringify(this.state.subInvoices))
        }
      }

      this.MobNative?.launchShoot(
        params.value,
        isSaleBilling,
        isTransportRole,
        isSale,
        config.API_HOST,
        params.isValid
      );
    } else if (message === "inputChange" && params.name === "Search") {
      this.setState({ filters: params.value });
      if (
        typeof params?.value &&
        params?.value?.trim().length !== 1 &&
        params?.value?.length !== 1
      ) {
        if (this.searchJob) {
          clearTimeout(this.searchJob);
          this.searchJob = null;
        }

        if (params?.value?.length === 0) {
          this.searchJob = setTimeout(() => {
            this.searchJob = null;
            this.getTaskList();
          }, 0);
        } else {
          this.searchJob = setTimeout(() => {
            this.searchJob = null;
            this.getTaskList();
          }, 2000);
        }
      }
      // } else if (message === "changePassword" ) {
      //   this.setState({ })
    } else if (message === "inputChange" && params.name === "startDate") {
      let start = moment(params.value, "DD/MM/YYYY");
      let end = moment(this.state.endDate, "DD/MM/YYYY");
      // console.log('inputChange startDate start: ', start);
      // console.log('inputChange startDate end: ', end);
      console.log(
        " moment.duration(start.diff(end)).asDays()",
        moment.duration(end.diff(start)).asDays()
      );
      const diff = moment.duration(end.diff(start)).asDays();
      if (Math.ceil(diff) > 31) {
        this.setState({ endDate: start.add(31, "days").toDate() });
      }
      if (diff < 0 && Math.floor(diff) < 0) {
        this.setState({ endDate: params.value });
      }

      this.setState({ completeWholeList: false, errorWholeList: ""});
      this.setState({ startDate: params.value });
      setTimeout(() => {
        this.getTaskList();
        this.handleGetAllInvoice();
      }, 0);
    } else if (message === "inputChange" && params.name === "endDate") {
      let end = moment(params.value, "DD/MM/YYYY");
      let start = moment(this.state.startDate, "DD/MM/YYYY");

      // console.log('inputChange endDate start: ', start);
      // console.log('inputChange endDate end: ', end);
      // console.log(" moment.duration(start.diff(end)).asDays()", moment.duration(start.diff(end)).asDays())
      const diff = moment.duration(end.diff(start)).asDays();

      if (Math.ceil(diff) > 31) {
        this.setState({ startDate: end.subtract(31, "days").toDate() });
      }

      if (diff < 0 && Math.floor(diff) < 0) {
        this.setState({ startDate: params.value });
      }

      this.setState({ endDate: params.value });
      this.setState({ completeWholeList: false, errorWholeList: ""});
      setTimeout(() => {
        this.getTaskList();
        this.handleGetAllInvoice();
      }, 0);
    } else if (message === "settingNoti") {
      alert(" setting Noti ");
    } else if (message === "choose" && params.name === "groupStatus") {
      this.handleGetIndividualInvoice(params.value);
    } else if (message === "pagination") {
      let prepare = [...this.state.activePageList];
      prepare[params.name] = params.value;
      console.log("pagination === >prepare", prepare);
      this.setState({ activePageList: prepare });
      setTimeout(() => {
        this.handleGetIndividualInvoice(this.state.selectedGroupStatus);
      }, 0);
    } else {
      console.log(" should not see me");
    }
  };

  getPositionKey = (positionCode) => {
    let positionKey: string = "";
    if (this.state.position_code) {
      positionKey = Object.keys(POSITION_CODE).find((key) =>
        POSITION_CODE[key].includes(this.state.position_code as string)
      ) as string;
      return positionKey;
    } else {
      console.error("Can't detect position_code ");
      return null;
    }
  };
  getData = async () => {
    await this.getEmployeeExtra();
    await this.getInvNotification();

    // Get Position Code
    // const [r, e, n] = await DocumentImageList.create({
    //     apiToken: this.state.apiToken,
    //     data: {
    //         invoice: 433,
    //         document: null,
    //         base64: this.state.data
    //     },
    // })
    // if (e) {
    //     console.log(error)
    //     console.log(network)
    //     return
    // }

    if (this.state.position_code) {
      let positionKey = this.getPositionKey(this.state.position_code);
      this.setState({ positionKey: positionKey });
    }

    await this.getTaskList();
    this.handleGetAllInvoice();
  };

  checkInvoiceForUpdate = async (data: InvoiceSerializer[]) => {
    let invoice_update_data = data.filter(
      (item: any) => item.all_document_scanned
    );
    if (invoice_update_data.length > 0) {
      invoice_update_data.map(async (item) => {
        if (item.allowed_actions.length > 0) {
          const [r, e, n] = await InvoiceDetail.patch({
            pk: item.id,
            apiToken: this.state?.apiToken,
            data: {
              action: item.allowed_actions[0],
              debounce_key: `${item.id}_${item.edited}_${item.allowed_actions[0]}`
            },
          });
        }
        this.getData();
      });
    } else {
      this.setState({
        invoices: data as InvoiceSerializer[],
        loading: false,
      });
    }
  };

  getTaskList = async () => {
    console.log("Mob.tsx getTaskList");
    this.handleGetTotalInvoice();
  };

  getEmployeeExtra = async () => {
    console.log("Mob.tsx getEmployeeExtra");

    const mobNative =
      typeof (window as any).MobNative !== "undefined"
        ? (window as any).MobNative
        : null;

    let deviceId = "";
    if (mobNative) {
      deviceId = mobNative.getDeviceId();
    } else {
      deviceId = "Computer";
    }

    const [r, e, n] = await LoginEmployee.list({
      apiToken: this.state.apiToken,
      params: {
        device_id: deviceId,
      },
    });
    if (e) {
      console.log(e, n);
      return;
    }
    console.log("getEmployeeExtra response: ", r);
    if (r.items.length > 0) {
      this.setState({
        employee: r.items[0],
        position_code: r.items[0].position_code,
      });
    }
  };

  getToken = () => {
    var apiToken: string;
    if (this.MobNative) {
      apiToken = this.MobNative?.getToken();
    } else {
      apiToken = this.props.cookies.get("apiToken");
    }
    return apiToken;
  };

  setToken = (apiToken: string) => {
    if (this.MobNative) {
      this.MobNative?.setToken(apiToken);
    } else {
      this.props.cookies.set("apiToken", apiToken);
    }
    this.setState({
      login: true,
      apiToken: apiToken,
    });
  };

  removeToken = () => {
    if (this.MobNative) {
      this.MobNative?.removeToken();
    } else {
      this.props.cookies.set("apiToken", "");
    }
    this.setState({
      login: false,
      apiToken: null,
    });
  };

  getInvNotification = async () => {
    const [response, error, network] = await InvNotificationList.list({
      params: {},
      apiToken: this.getToken(),
    });
    if (error) {
      console.log(error);
      console.log(network);
      return;
    }
    console.log(response);
    this.setState({
      noti: response.items,
    });
  };

  handleGetTotalInvoice = async () => {
    const positionNoneDate = ['transport', 'sale']
    let start_billing_date = ""
    let end_billing_date = ""
    if(!positionNoneDate.includes(this.state.position_code)){
      start_billing_date = dateToStringWithoutTimeAD(this.state.startDate)
      end_billing_date = dateToStringWithoutTimeAD(this.state.endDate)
    }
    this.setState({ loading: true });
    const [response, error, network] = await GetTotalInvoice.retrieve({
      params: {
        serial: this.state.filters,
        // start_billing_date: dateToStringWithoutTimeAD(this.state.startDate),
        // end_billing_date: dateToStringWithoutTimeAD(this.state.endDate),
        start_billing_date: start_billing_date,
        end_billing_date: end_billing_date,
      },
      apiToken: this.state.apiToken,
    });
    this.setState({ loading: false });

    if (error) {
      if (network.response.status === 401) {
        this.setState({ loading: false });
        return this.handleEvent({ message: "Logout", params: {} });
      }
      console.log(error);
      console.log(network);
      console.log("Mob.tsx getTaskList Error");
      this.setState({ loading: false, invoices: [], subInvoices: [] });
      return;
    } else {
      this.prepareStatusLists(response);
    }
  };

  handleGetAllInvoice = async () => {


    const positionNoneDate = ['transport', 'sale']
    console.log(
      "handleGetAllInvoice this.state.position_code",
      this.state.position_code
    );

    this.setState({alreadySuccessSyncAllInvoice: false})

    if (!this.state.positionKey) {
      return;
    }

    console.log(
      " handleGetAllInvoice : this.state.positionKey: ",
      this.state.positionKey
    );

    const groupStatus =
      COLUMN_TASK_LIST_STATE[this.state.positionKey as string];
    console.log(" handleGetAllInvoice groupStatus", groupStatus);
    var allStatus = groupStatus.join().split(",");
    this.setState({completeWholeList: false, errorWholeList: ""})
    let start_billing_date = ""
    let end_billing_date = ""
    if(!positionNoneDate.includes(this.state.position_code)){
      start_billing_date = dateToStringWithoutTimeAD(this.state.startDate)
      end_billing_date = dateToStringWithoutTimeAD(this.state.endDate)
    }

    if (this.source) {
      this.source.cancel('Cancel previous taskList get')
    } 
    const CancelToken = axios.CancelToken;  
    this.source = CancelToken.source();


    let appVersion = this.MobNative?.getApplicationVersion().toString().split(".")
    const majorVersion = Number(appVersion?.shift())
    const minorVersion = Number(appVersion?.pop())

    // New 
    if (majorVersion >= 1 && minorVersion >= 45) {
      const [responseL, errorL, networkL] = await TaskSerialList.list({
        params: {
          page_size: 60000,
          status: allStatus,
          start_billing_date: start_billing_date,
          end_billing_date: end_billing_date,
          // start_billing_date: dateToStringWithoutTimeAD(this.state.startDate),
          // end_billing_date: dateToStringWithoutTimeAD(this.state.endDate),
        },
        apiToken: this.state.apiToken,
        extra: {cancelToken: this.source.token},
      });
  
      if (errorL) {
        if (networkL.response.status === 401) {
          this.setState({ loading: false });
          this.MobNative?.setInvoiceData(JSON.stringify([]));
          this.setState({errorWholeList: "ขาดสิทธิ์การเข้าถึง"})
          return this.handleEvent({ message: "Logout", params: {} });
        }
        console.log("handleGetAllInvoice error:", errorL);
        console.log("handleGetAllInvoice network:", networkL);
        this.setState({errorWholeList: errorL})
        console.log("Mob.tsx getTaskList Error");
  
        return;
      } else {
        console.log("ready to Scan !!!!!!! ");
        this.setState({alreadySuccessSyncAllInvoice: true})
        this.setState({allForScanInvoices: responseL.results})
        this.MobNative?.setInvoiceData(JSON.stringify(responseL.results));
        this.setState({completeWholeList: true})
      }
    } else { 
      // Old 
      const [response, error, network] = await TaskList.list({
        params: {
          page_size: 60000,
          status: allStatus,
          start_billing_date: start_billing_date,
          end_billing_date: end_billing_date,
          // start_billing_date: dateToStringWithoutTimeAD(this.state.startDate),
          // end_billing_date: dateToStringWithoutTimeAD(this.state.endDate),
        },
        apiToken: this.state.apiToken,
        extra: {cancelToken: this.source.token},
      });

      if (error) {
        if (network.response.status === 401) {
          this.setState({ loading: false });
          this.MobNative?.setInvoiceData(JSON.stringify([]));
          this.setState({errorWholeList: "ขาดสิทธิ์การเข้าถึง"})
          return this.handleEvent({ message: "Logout", params: {} });
        }
        console.log("handleGetAllInvoice error:", error);
        console.log("handleGetAllInvoice network:", network);
        this.setState({errorWholeList: error})
        console.log("Mob.tsx getTaskList Error");

        return;
      } else {
        console.log("ready to Scan !!!!!!! ");
        this.setState({alreadySuccessSyncAllInvoice: true})
        this.setState({allForScanInvoices: response.results})
        this.MobNative?.setInvoiceData(JSON.stringify(response.results));
        this.setState({completeWholeList: true})
      }
    }
  
  };

  handleGetIndividualInvoice = async (idx) => {
    const positionNoneDate = ['transport', 'sale']
    console.log("this.state.statusList", this.state.statusList, "idx", idx);
    const groupStatus =
      COLUMN_TASK_LIST_STATE[this.state.positionKey as string];
    console.log("groupStatus", groupStatus);
    this.setState({ loading: true, selectedGroupStatus: idx });
    
    let start_billing_date = ""
    let end_billing_date = ""
    if(!positionNoneDate.includes(this.state.position_code)){
      start_billing_date = dateToStringWithoutTimeAD(this.state.startDate)
      end_billing_date = dateToStringWithoutTimeAD(this.state.endDate)
    }

    const [response, error, network] = await TaskList.list({
      params: {
        page_size: limitPageSize,
        page: this.state.activePageList[idx],
        status: groupStatus[idx],
        serial: this.state.filters,
        start_billing_date: start_billing_date,
        end_billing_date: end_billing_date,
        // start_billing_date: dateToStringWithoutTimeAD(this.state.startDate),
        // end_billing_date: dateToStringWithoutTimeAD(this.state.endDate),
      },
      apiToken: this.state.apiToken,
    });
    this.setState({ loading: false });

    if (error) {
      if (network.response.status === 401) {
        this.setState({ loading: false });
        return this.handleEvent({ message: "Logout", params: {} });
      }
      console.log(error);
      console.log(network);
      console.log("Mob.tsx getTaskList Error");
      this.setState({ loading: false, invoices: [], subInvoices: [] });
      return;
    } else {
      console.log("Mob.tsx getTaskList Success");
      console.log("response.count: ", response.count);
      console.log("response.results.length: ", response.results.length);
      this.setState({ subInvoices: response.results });
      this.checkInvoiceForUpdate(response.results);
    }
  };

  prepareStatusLists = async (data) => {
    // Get Position Code

    let positionKey = null;
    if (!this.state.positionKey) {
      if (this.state.position_code) {
        positionKey = this.getPositionKey(this.state.position_code);
        this.setState({ positionKey: positionKey });
      }
    } else {
      positionKey = this.state.positionKey
    }

    console.log("positionKey: ", positionKey); // e.g. TRANSPORT
    console.log("data: ", data); // e.g. TRANSPORT

    var invoiceCountList = [];
    var nameList = [];

    COLUMN_TASK_LIST_STATE[positionKey as string].forEach((item, idx) => {
      const filtered = Object.keys(data)
        .filter((key) => item.includes(key))
        .reduce((obj, key) => {
          obj[key] = data[key];
          return obj;
        }, {});
      console.log("filtered", filtered);
      var count = 0;
      for (const key in filtered) {
        count = filtered[key] + count;
      }
      invoiceCountList.push(count);
      nameList.push(COLUMN_TEXT_TASK_LIST[positionKey as string][idx]);
    });
    console.log("check COLUMN_TEXT_TASK_LIST[positionKey as string]: ");
    COLUMN_TEXT_TASK_LIST[positionKey as string].forEach((item) => {
      console.log("COLUMN_TEXT_TASK_LIST", item);
    });

    const statusList = nameList.map((item, idx) => {
      return { count: invoiceCountList[idx], label: item, key: item };
    });
    const activePage = nameList.map((item) => 1);
    const total = invoiceCountList.reduce((a, b) => a + b, 0);
    const prepare = {
      total: total,
      status: statusList,
    };
    console.log(
      " total: ",
      total,
      "invoiceCountList: ",
      invoiceCountList,
      "nameList: ",
      nameList,
      "prepare",
      prepare
    );
    this.setState({ statusList: prepare });
    this.setState({ activePageList: activePage });
    this.handleGetIndividualInvoice(this.state.selectedGroupStatus);
  };

  render() {
    const apiToken = this.getToken();
    return (
      <AuthenProvider
        value={{ apiToken: this.state.apiToken, login: this.state.login }}
      >
        <ProfileProvider value={this.state.employee}>
          <div className="mob" style={{ height: "100%", maxHeight: "100%" }}>
            {!this.state.login || this.state.sub === "Login" ? (
              <CardLogin
                {...this.state}
                onEvent={this.handleEvent}
                onHostnameChange={(hostName: string) =>
                  this.setState({ hostName })
                }
              />
            ) : this.state.sub === "TaskList" ? (
              <CardTaskList
                softReload={this.getData}
                tab="TaskList"
                apiToken={apiToken}
                // Pagination
                position_code={this.state.position_code}
                getTotalInvoice={this.handleGetTotalInvoice}
                getIndividualInvoice={this.handleGetIndividualInvoice}
                statusList={this.state.statusList}
                subInvoices={this.state.subInvoices}
                selectedGroupStatus={this.state.selectedGroupStatus}
                activePageList={this.state.activePageList}
                filters={this.state.filters}
                alreadySuccessSyncAllInvoice={this.state.alreadySuccessSyncAllInvoice}
                // invoices={this.state.invoices}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onEvent={this.handleEvent}
                loading={this.state.loading}
              />
            ) : // this.state.card === "CardNoti" ?
            // <CardNoti {...this.state} onEvent={this.handleEvent} /> :

            this.state.sub === "Profile" ? (
              <div
                style={{
                  height: "100%",
                  maxHeight: "100%",
                  overflow: "hidden",
                }}
              >
                <TabLayout onEvent={this.handleEvent} tab="Profile">
                  <CardProfile
                    employeeInfo={this.state.employee}
                    onEvent={this.handleEvent}
                  />
                </TabLayout>
              </div>
            ) : this.state.sub === "Noti" ? (
              <CardNoti
                {...this.state}
                tab={"Noti"}
                onEvent={this.handleEvent}
              />
            ) : this.state.sub === "Other" ? (
              <TabLayout onEvent={this.handleEvent} tab="Other">
                <CardOtherMenu onEvent={this.handleEvent} />
              </TabLayout>
            ) : (
              <> </>
            )}
          </div>
        </ProfileProvider>
      </AuthenProvider>
    );
  }
}

export default withCookies(MobInv);
