import { Portal } from "@material-ui/core";
import React, {useEffect, useState} from "react" 
import {Icon, Button, Input} from "semantic-ui-react"
import { createTrue } from "typescript";
import {
  SetPassword,
  RequestToken
} from "../react-lib/apis/issara/apps/TRK/INVmanual";


const CardCreatePassword = (props: any) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState(false);

  const handleSetPassword = async () => {

    const [response, error, network] = await SetPassword.post({
      data: {
        password: password,
        confirm_password: confirmPassword
      },
      apiToken: props.apiToken
    });
    if (!error) {
      console.log(response);
      props.onEvent({ message : "setPassword", params : { value: { success: true} }})
    } else {
      console.log(error);
      console.log(network);
      props.onEvent({ message : "setPassword",  params : { value: { success: false} }})
    }
  }
  useEffect(() => {
    if (checkPasswordPolicy(password, confirmPassword)) {
      setVerifyPassword(true);
    } else {
      setVerifyPassword(false);
    }
  }, [password, confirmPassword]);
  const checkPasswordPolicy = (another: string, password: string) => {
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var pass = !!password.match(lowerCaseLetters);
    pass = pass && !!password.match(upperCaseLetters);
    pass = pass && !!password.match(numbers);
    pass = pass && password.length >= 8;

    if (pass && !password.localeCompare(another)) {
      return true;
      // setShowAlertText(false)
    } else {
      return false;
      // setShowAlertText(true)
    }
  };

  return (
    <div
      className="mob-create-pass"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "20px"
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#247DAF",
          color: "white",
          textAlign: "center",
          height: "59px"
        }}
      >
        เปลี่ยนรหัสผ่าน
      </div>

      <div
        style={{
          position: "absolute",
          left: 0,
          top: "5px",
          height: "59px",
          width: "59px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#247DAF",
          color: "white"
        }}
        onClick={e =>
          props.onEvent({
            message: "clickButton",
            params: { name: "goBack" }
          })
        }
      >
        <Icon size="large" name="angle left" />
      </div>

      <div style={{ textAlign: "center" }}>
        <div style={{ margin: "10px" }}>กรุณาระบุรหัสผ่าน</div>
        <br />
        <div style={{ color: verifyPassword ? "gray" : "red" }}>
          <div style={{ margin: "10px" }}>เป็นตัวอักษรภาษาอังกฤษ</div>
          <div style={{ margin: "10px" }}>ตัวใหญ่ตัวเล็กและตัวเลข</div>
          <div style={{ margin: "10px" }}>ความยาวไม่น้อยกว่า 8 ตัวอักษร</div>
        </div>
      </div>
      <div style={{ width: "90%" }}>
        <div style={{ margin: "10px" }}>
          รหัสผ่านใหม่<span style={{ color: "red" }}>*</span>:
        </div>
        <Input
          style={{ width: "100%" }}
          value={password}
          onChange={(e, v) => setPassword(v.value)}
          type="password"
        />
        <div style={{ margin: "10px" }}>
          ยืนยันรหัสผ่าน<span style={{ color: "red" }}>*</span>:
        </div>
        <Input
          style={{ width: "100%" }}
          value={confirmPassword}
          onChange={(e, v) => setConfirmPassword(v.value)}
          type="password"
        />
      </div>
      <Button
        size="big"
        style={{ backgroundColor: "#247DAF", color: "white" }}
        disabled={!verifyPassword}
        onClick={ handleSetPassword 
        }
      >
        ต่อไป
      </Button>
      <div></div>
    </div>
  );
};

export default CardCreatePassword;