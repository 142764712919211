import React, { useState, useEffect } from "react";
import { Icon, Input, Modal, Button } from "semantic-ui-react";
import { STATUS_COLOR, STATUS } from "../Common";
import { InvoiceSerializer, InvoiceDetail } from "../../src/react-lib/apis/issara/apps/TRK/INVInvoice";
import CardInvoiceDetailInfo from "./CardInvoiceDetailInfo";
import CardTaskDetail from "./CardTaskDetail";
import TabLayout from "./TabLayout";

const CardNoti = (props: any) => {
  var notiData: any = {};
  const [searchItem, setSearchItem] = useState<String>("");
  const [sub, setSub] = useState<String>("List");
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceSerializer>();
  const [currentList, setCurrentList] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

    if(props.noti){
      if(searchItem){
        for (const item of props.noti.filter((o:any) => o.serial.includes(searchItem))){
          const [y, m, d] = item.date.split("T")[0].split("-");
        const date_str = `${d}/${m}/${y}`;
        if (!(date_str in notiData)) {
          notiData[date_str] = [];
        }
        notiData[date_str].push(item);
        }
      }
      else{
      for (const item of props.noti) {
        const [y, m, d] = item.date.split("T")[0].split("-");
        const date_str = `${d}/${m}/${y}`;
        if (!(date_str in notiData)) {
          notiData[date_str] = [];
        }
        notiData[date_str].push(item);
      }
    }
  }

  const handleOnClickInvoice = (invoiceId:number) => {
    console.log(invoiceId, props)
    if(!invoiceId){
      return
    }
    let invoiceItem = props.invoices.filter((obj:any) => obj.id === invoiceId)
    if(invoiceItem.length > 0){
      console.log(invoiceItem)
      setSelectedInvoice(invoiceItem[0]);
      setSub("Detail");
    }else{
      setOpenModal(true);
    }
  }
  
  const handleEvent = ({ message, params }: { message: string; params: any }) => {
    if(message === 'clickTab'){
      setSub("List")
    }
  }

  return sub === "List"? (
    <div style={{ height: "100%", minHeight: "100%" }}>
        <TabLayout tab={props.tab} onEvent={props.onEvent}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100vh - 65px)",
            }}
          >
      <div
        style={{
          color: "#247DAF",
          padding: "20px",
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        การแจ้งเตือน
      </div>
      <hr style={{ border: "1px solid #C4C4C4", margin:"0px"}}/>
      <Input
        value={searchItem}
        icon="search"
        iconPosition="left"
        placeholder="ค้นหา"
        style={{margin: "15px 10px", borderRadius:"10px"}}
        onChange={(e) => {setSearchItem(e.target.value)}}
      >
      </Input>
     
      <div style={{height:"100%",overflow:"auto", backgroundColor: "#E5E5E5"}}>
      {Object.keys(notiData).map((date: string, date_index: number) => (
        <div key={date_index} style={{ lineHeight: "2em" }}>
          {notiData[date].map((item: any, item_index: number) => (
            <div key={date_index + "_" + item_index} onClick={()=>{

              handleOnClickInvoice(item.invoice)
              }}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "15px 15px 5px 18px",
                  fontSize: "20px",
                  color: STATUS_COLOR[item.status],
                  flexDirection: "column",
                  marginBottom: Object.keys(notiData).length === (date_index +1) && notiData[date].length === (item_index + 1) ? "0px" : "2px" 
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  }}
                >
                  <div>
                    <Icon name="suitcase" />
                    &ensp;{item.serial}
                  </div>
                  <div
                    style={{
                      textAlign: "end",
                      fontSize: "16px",
                      color: "#4c4c4c",
                    }}
                  >
                    {date + " " + item.time}
                  </div>
                </div>
                <div style={{ fontSize: "17px", color: "#4c4c4c" }}>
                  {STATUS[item.status]}
                </div>
              </div>
              { Object.keys(notiData).length === (date_index +1) && notiData[date].length === (item_index + 1) && <div style={{height: "6px", backgroundImage: "linear-gradient(180deg, #E5E5E5, white)"}}>  </div> }
            </div>
          ))}
        </div>
      ))}
      </div>
      <Modal open={openModal} onClose={()=>{setOpenModal(false)}}>
        <Modal.Content> 
          <p>
          ไม่สามารถเรียกดู Invoice ได้ 
          </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" content="ตกลง" onClick={()=> {setOpenModal(false)}}/>
          </Modal.Actions>
        </Modal>
    </div>
    </TabLayout>
    </div>
  ): sub === "Detail"?(
  <CardTaskDetail
        tab={props.tab}
        loading={props.loading}
        apiToken={props.apiToken}
        position_code={props.position_code}
        invoice={selectedInvoice}
        onEvent={handleEvent}
        readonly={true}
        goToInvoiceDetailInfo={(list: any[]) => {
          setSub("InvoiceDetailInfo");
          setCurrentList(list);
        }}
        goToPreviousInvoiceDetailInfo={() => {
          setSub("InvoicePreviousDetailInfo")
        }}
      />
    ) : sub === "InvoiceDetailInfo" ? (
      <CardInvoiceDetailInfo
        goBack={() => {
          setSub("Detail"); 
        }}
        // invoice={this.state.selectedInvoice}
        // currentList={this.state.currentList}
      />
    ) : sub === "InvoicePreviousDetailInfo" ? (
      <CardInvoiceDetailInfo
        goBack={() => {
          setSub("Detail")
        }}
        invoice={selectedInvoice}
      />):(<div></div>) ;
};

export default CardNoti;
