import React from "react";
import { Icon } from "semantic-ui-react";

const TabLayout = (props: any) => (

  <>
    <div style={{ flex: 1, overflow: "hidden" }}>{props.children}</div>
    <div
      style={{
        display: "flex",
        height: "65px",
        overflow: "hidden",
        position: "sticky",
        bottom: 0,
        borderTop: "1px solid lightgrey"
      }}
    >
      <TabItem name="Profile" onEvent={props.onEvent}>
        <Icon
          name="user circle"
          color={props.tab === "Profile" ? "blue" : "grey"}
          size="large"
        />
      </TabItem>
      <TabItem name="TaskList" onEvent={props.onEvent}>
        <Icon
          name="suitcase"
          color={props.tab === "TaskList" ? "blue" : "grey"}
          size="large"
        />
      </TabItem>
      <TabItem name="Camera" onEvent={props.onEvent}>
        <Icon
          name="expand"
          color={props.tab === "Camera" ? "blue" : "grey"}
          size="large"
        />
      </TabItem>
      <TabItem name="Noti" onEvent={props.onEvent}>
        <Icon
          name="bell"
          color={props.tab === "Noti" ? "blue" : "grey"}
          size="large"
        />
      </TabItem>
      <TabItem name="Other" onEvent={props.onEvent}>
        <Icon
          name="bars"
          color={props.tab === "Other" ? "blue" : "grey"}
          size="large"
        />
      </TabItem>
    </div>
  </>
);

const TabItem = (props: any) => (
  <div
    style={{
      flex: 1,
      textAlign: "center",
      paddingTop: "10px",
      backgroundColor: "white"
    }}
    onClick={() => {
      props.onEvent({
        message: "clickTab",
        params: {
          name: props.name
        }
      });
    }}
  >
    {props.children}
  </div>
);

export default TabLayout;
