import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import invSetting from '../../../../../config/config';
import { Response, Error } from '../../common'
import { AnyARecord } from 'dns'

export interface InvoiceCompactSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    serial?: any,
    customer?: any,
    date?: any,
    delivery_address?: any,
    amount?: any,
    sale?: any,
    transport?: any,
    billing_type?: any,
    box_count?: any,
    active?: any,
    external?: any,
    shipping_company?: any,
    customer_name?: any,
    customer_code?: any,
    sale_name?: any,
    transport_name?: any,
    status_label?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface InvoiceSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    serial?: any,
    customer?: any,
    date?: any,
    delivery_address?: any,
    amount?: any,
    sale?: any,
    transport?: any,
    billing_type?: any,
    box_count?: any,
    cost_per_box?: any,
    active?: any,
    external?: any,
    shipping_company?: any,
    customer_name?: any,
    customer_code?: any,
    sale_name?: any,
    transport_name?: any,
    status_label?: any,
    related_documents?: any,
    action?: any,
    loss_reason?: any,
    allowed_actions?: any,
    billing_type_label?: any,
    remark?: any,
    reason?: any,
    debounce_key?: string,
}


/* ['generics.ListAPIView'] */
/* params: 'serial_list', 'serial', 'ym', 'from_date', 'to_date', 'customerName', 'customer_name', 'customer_code', 'sale_name', 'delivery_address', 'serial', 'sortby', 'ascending' */
/* data:  */
export const InvoiceList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.ListAPIView'] */
/* params:  */
/* data:  */
export const TaskList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/task-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
export const InvoiceDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: InvoiceSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: InvoiceSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-detail/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${invSetting.API_HOST}/apis/TRK/invoice-detail/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${invSetting.API_HOST}/apis/TRK/invoice-detail/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const InvoiceExternalBack:
{
  patch:    ({params, data, apiToken, extra}:
    {  
       params?: any,
       data?: any,
       apiToken?: any,
       extra?: any,
   }) => any,
} = {
  patch: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${invSetting.API_HOST}/apis/TRK/invoice-external-back-update/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
export const InvoiceUpdateAll:
{
  patch: ({params, data, apiToken, extra}:
    {  
       params?: any,
       data?: any,
       apiToken?: any,
       extra?: any,
   }) => any,
} = {
  patch: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
      if (apiToken) {
          base.client.defaults.headers["Authorization"] = "Token " + apiToken
      }
      const result = await to(base.client.patch(`${invSetting.API_HOST}/apis/TRK/invoice-update-all/`,
        { ...data
        },
        { params: params ? { ...params } : {},
          ...extra
        }
      )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
      return [result[1], result[0], result[2]]
    },
}

export const InvoiceCancelAll:
{
  patch: ({params, data, apiToken, extra}:
    {  
       params?: any,
       data?: any,
       apiToken?: any,
       extra?: any,
   }) => any,
} = {
  patch: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
      if (apiToken) {
          base.client.defaults.headers["Authorization"] = "Token " + apiToken
      }
      const result = await to(base.client.patch(`${invSetting.API_HOST}/apis/TRK/invoice-cancel-all/`,
        { ...data
        },
        { params: params ? { ...params } : {},
          ...extra
        }
      )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
      return [result[1], result[0], result[2]]
    },
}

export const InvoiceBillNote:
{
  list: ({params, data, apiToken, extra}:
    {  
       params?: any,
       data?: any,
       apiToken?: any,
       extra?: any,
   }) => any,
  patch: ({params, data, apiToken, extra}:
    {  
       params?: any,
       data?: any,
       apiToken?: any,
       extra?: any,
   }) => any,
} = {
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-billing-note/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  patch: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
      if (apiToken) {
          base.client.defaults.headers["Authorization"] = "Token " + apiToken
      }
      const result = await to(base.client.patch(`${invSetting.API_HOST}/apis/TRK/add-billing-note/`,
        { ...data
        },
        { params: params ? { ...params } : {},
          ...extra
        }
      )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
      return [result[1], result[0], result[2]]
    },

}
export const InvoiceDetailCNDN :
{ patch:    ({pk, params, data, apiToken, extra}:
  { pk: any, 
     params?: any,
     data?: InvoiceSerializer,
     apiToken?: any,
     extra?: any,
 }) => any,
} = {
  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${invSetting.API_HOST}/apis/TRK/invoice-cn-dn-detail/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const InvoiceDetailNoteList : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-detail/${pk}/note-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

