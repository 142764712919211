import React, { useState } from "react";
import { Divider } from "semantic-ui-react";
import "../Mob/CardOtherMenu.css";
import CardVerifyUsername from "./CardVerifyUsername";
import CardCreatePassword from "./CardCreatePassword";
import { findAllInRenderedTree } from "react-dom/test-utils";
import { TextareaAutosize } from "@material-ui/core";

const CardOtherMenu = (props: any) => {
  const [openCardCreatePassword, setOpenCardCreatePassword] = useState(false);
  const [openCardVerifyUsername, setOpenCardVerifyUsername] = useState(false);
  const [apiToken, setApiToken] = useState("")


  const metas = document.getElementsByTagName('meta');

  const getVersion = () => {
    for (let i = 0; i < metas.length; i++) {
      let version = metas[i].getAttribute('build-version')
      if (version) {
        if (version.includes("REACTBUILDVERSION")) {
          return "Debug Mode"
        } else {
          return version;
        }
      }
    } 
  }

  const getKotlinVersion = () => {
    const mobNative =
    typeof (window as any).MobNative !== "undefined"
      ? (window as any).MobNative
      : null;

    if (mobNative) {
      let appVersion = mobNative.getApplicationVersion()
      return appVersion
    } else {
      return "Unknown version"
    }

  }

  const handleChangePassword = ({message, params} : 
    {
      message: string, 
      params: {
        name: string,
        value: {success: boolean }
      }
    } ) => {
    
    if (message === "clickButton" )
    {
      if ( params.name === "goBack" ) {
        setOpenCardCreatePassword(false)
      } 
    } else if ( message === "setPassword") {
   
        if (params.value.success === true ) {
          alert("Change Password success")
          setOpenCardCreatePassword(false)
        } else {
          alert("Change Password failed")
        }
     
    }
    // props.onEvent({ message: "changePassword" })
  };

  const handleVerifyUsername = ( {message, params} : 
    {
      message: string, 
      params: {
        name: string,
        apiToken: string
      }}) => {
    if (message === "clickButton" )
    {
    
      if ( params.name === "createPassword" ) {
        setOpenCardCreatePassword(true)
        setApiToken(params.apiToken)
        setOpenCardVerifyUsername(false)
      } else if ( params.name === "goBack" ) {
        setOpenCardVerifyUsername(false)
      }
    }    
  }

  const handleSettingNoti = () => {
    props.onEvent({ message: "settingNoti" });
  };

  const handleContactUs = () => {
    alert("กรุณาติดต่อหัวหน้าแผนกหรือฝ่ายสารสนเทศ (IT) ที่เบอร์​ 02-999-9999");
  };

  return (
    <>
      {openCardVerifyUsername && <CardVerifyUsername onEvent={handleVerifyUsername}/>}
      {openCardCreatePassword && <CardCreatePassword apiToken={apiToken} onEvent={handleChangePassword} />}
      {!openCardVerifyUsername && !openCardCreatePassword && (
        <div className="mob-other-menu">
          <h2>เพิ่มเติม</h2>
          <div>
            <div className="row-wrapper-first" onClick={()=>{setOpenCardVerifyUsername(true)}}>
              <div className="header">เปลี่ยนรหัสผ่าน</div>
            </div>

            <div className="row-wrapper" onClick={handleSettingNoti}>
              <div className="header">ตั้งค่าแจ้งเตือน</div>
            </div>

            <div className="row-wrapper-gray">สนับสนุน</div>

            <div className="row-wrapper">
              <div className="header">เวอร์ชั่น Web</div>
              <div> {getVersion()} </div>
            </div>

            <div className="row-wrapper">
              <div className="header">เวอร์ชั่น Application </div>
              <div> {getKotlinVersion()} </div>
            </div>

            

            <div className="row-wrapper" onClick={handleContactUs}>
              <div className="header">ติดต่อ</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardOtherMenu;
