import React, { useState } from "react";
import { withCookies } from "react-cookie";
import { Input, Button, Icon } from "semantic-ui-react";

import config from "../../config/config";
import styles from "./SubLogin.module.css";

const MPC = "/static/images/logo_mpc.png";
const ANB ="/static/images/logo_anb.png";

export interface INTMloginViewDataType {
  Item12: string;
  Item12Disabled: boolean;
  Item30: string;
  Item30Disabled: boolean;
}

export const INTMloginInitialViewData: INTMloginViewDataType = {
  Item12: "",
  Item12Disabled: false,
  Item30: "",
  Item30Disabled: false
};

export default withCookies((props: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const children = React.Children.toArray(props.children);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%"
      }}
    >
      <img style={{ width: "50%", marginTop: props.cookies.get('hostName') === "MPC"? "100px" : "50px" }} src={props.cookies.get('hostName') === "MPC"? `${process.env.PUBLIC_URL + MPC}`: `${process.env.PUBLIC_URL + ANB}`} />
      <div
        style={{
          border: "solid #247DAF44 2px",
          borderRadius: "25px",
          width: "90%",
          marginTop: process.env.PUBLIC_URL + props.cookies.get('hostName') === "MPC"? "80px" : "40px"
        }}
      >
        <div>
          <div
            style={{ color: "#247DAF", textAlign: "center", marginTop: "10px" }}
          >
            <h2>Invoice Tracking</h2>
          </div>
        </div>
        <div style={{ margin: "20px" }}>
          <Input
            value={props.viewData.Item12}
            icon
            iconPosition="left"
            disabled={props.viewData.Item12Disabled}
            onChange={e => {
              props.onEvent({
                message: "inputChange",
                params: {
                  view: "INTMlogin",
                  name: "Item12",
                  value: e.target.value
                }
              });
            }}
            placeholder="ชื่อผู้ใช้ / เบอร์มือถือ"
            style={{ height: "100%", width: "100%" }}
          >
            <input />
            <Icon name="user" color="blue" />
          </Input>
        </div>
        <div style={{ margin: "20px" }}>
          <Input
            value={props.viewData.Item30}
            icon
            iconPosition="left"
            disabled={props.viewData.Item30Disabled}
            onChange={e => {
              props.onEvent({
                message: "inputChange",
                params: {
                  view: "INTMlogin",
                  name: "Item30",
                  value: e.target.value
                }
              });
            }}
            placeholder="รหัสผ่าน"
            style={{ height: "100%", width: "100%" }}
          >
            <input type={showPassword ? "text" : "password"} />
            <Icon name="lock" color="blue" />
            <Icon
              style={{
                right: "0px",
                left: "auto",
                cursor: "pointer",
                zIndex: 100
              }}
              link
              name={showPassword ? "eye" : "eye slash"}
              color="grey"
              onClick={() => setShowPassword(!showPassword)}
            />
          </Input>
        </div>
        <div
          style={{
            marginLeft: "40px",
            marginRight: "40px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
        >
          <Button
            disabled={props.viewData.Item16Disabled}
            onClick={e => {
              props.onEvent({
                message: "clickButton",
                params: {
                  view: "INTMlogin",
                  name: "Item16"
                }
              });
            }}
            style={{
              color: "White",
              backgroundColor: "#247DAF",
              height: "100%",
              width: "100%"
            }}
          >
            เข้าสู่ระบบ
          </Button>
        </div>
        <div
          style={{
            marginRight: "40px",
            marginLeft: "40px",
            marginBottom: "40px",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{ color: "#247DAF" }}
            onClick={e =>
              props.onEvent({
                message: "clickButton",
                params: { name: "register" }
              })
            }
          >
            <p>ลงทะเบียน</p>
          </div>
          <div
            style={{ color: "#247DAF" }}
            onClick={e =>
              props.onEvent({
                message: "clickButton",
                params: { name: "register" }
              })
            }
          >
            <p>ลืมรหัสผ่าน</p>
          </div>
        </div>
      </div>
      <div style={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "flex-end",
        justifyContent: "center"
      }}>
        <div style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "44px",
          alignItems: "center",
          background: "#247daf",
          fontSize: "15px",
          color: "white",
          fontWeight: "bold"
        }} onClick={()=> {props.onEvent({
          message: "clickButton",
          params: { name: "company" }
        })}}>
        <Icon name="building outline" />
        เลือกบริษัท
        </div>
      </div>
    </div>
  );
});

// const Sub = (props: any) => {
//     const children = React.Children.toArray(props.children)
//     return (
//         <div className={styles.container}>
//             <div className={styles.Item1}>
//                 <div
//                     style={{height: "100%", width: "100%"}}
//                 >
//                     <img style={{height: "100%"}} src={logo} />
//                 </div>
//             </div>
//             <div className={styles.Item2}>
//                 <div
//                     style={{border: "solid #247DAF44 2px", borderRadius: "25px", height: "100%", width: "100%"}}
//                 >
//                     <p></p>
//                 </div>
//             </div>
//             <div className={styles.Item4}>
//                 <div
//                     style={{color:"#247DAF", textAlign: "center", marginTop:"10px"}}
//                 >
//                     <h2>Invoice Tracking</h2>
//                 </div>
//             </div>
//             <div className={styles.Item12}>
//                 <Input
//                     value={props.viewData.Item12}
//                     icon iconPosition='left'
//                     disabled={ props.viewData.Item12Disabled }
//                     onChange={(e) => { props.onEvent({
//                         message: 'inputChange',
//                         params: {
//                             view: 'INTMlogin',
//                             name: 'Item12',
//                             value: e.target.value
//                         }
//                     })}}
//                     placeholder="username"
//                     style={{height: "100%", width: "100%"}}
//                 ><input />
//                 <Icon name='user' color='blue' />
//                 </Input>
//             </div>
//             <div className={styles.Item30}>
//                 <Input
//                     value={props.viewData.Item30}
//                     icon iconPosition='left'
//                     disabled={ props.viewData.Item30Disabled }
//                     onChange={(e) => { props.onEvent({
//                         message: 'inputChange',
//                         params: {
//                             view: 'INTMlogin',
//                             name: 'Item30',
//                             value: e.target.value
//                         }
//                     })}}
//                     placeholder="password"
//                     style={{height: "100%", width: "100%"}}
//                 ><input type="password" />
//                 <Icon name='lock' color='blue' />
//                 </Input>
//             </div>
//             <div className={styles.Item16}>
//                 <Button
//                     disabled={ props.viewData.Item16Disabled }
//                     onClick={(e) => { props.onEvent({
//                         message: 'clickButton',
//                         params: {
//                             view: 'INTMlogin',
//                             name: 'Item16'
//                         }
//                     })}}
//                     style={{color:"White", backgroundColor: "#247DAF", height: "100%", width: "100%"}}
//                 >
//                     เข้าสู่ระบบ
//                 </Button>
//             </div>
//             <div className={styles.Item18}>
//                 <div
//                     style={{color:"#247DAF"}}
//                 >
//                     <p>ลงทะเบียน</p>
//                 </div>
//             </div>
//             <div className={styles.Item19}>
//                 <div
//                     style={{color:"#247DAF"}}
//                 >
//                     <p>ลืมรหัสผ่าน</p>
//                 </div>
//             </div>
//         </div>
//     )
// }

const getTsName = (cssClass: string) => {
  return cssClass
    .split("-")
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join("");
};
