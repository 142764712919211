import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import invSetting from '../../../../../config/config';
import { Response, Error } from '../../common'

export interface InvNotificationSerializer {
    id?: any,
    date?: any,
    employee?: any,
    message?: any,
    params?: any,
    read?: any,
}


/* ['generics.ListAPIView'] */
/* params:  */
/* data:  */
export const InvNotificationList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/inv-notification/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

