import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Button,
  Modal,
  Radio,
  Icon,
  Dropdown,
  Input,
  Dimmer,
  Loader,
  Label,
} from "semantic-ui-react";
import TabLayout from "./TabLayout";
import { STATUS, STATUS_FROM_ACTION, POSITION, STATE_BACKEND } from "../Common";
import { InvoiceDetail } from "../react-lib/apis/issara/apps/TRK/INVInvoice";
import {
  CheckInvoiceChangeStateByPresentUser,
  InvoiceDetailInvoiceRelatedDocumentFromStatusView,
} from "../react-lib/apis/issara/apps/TRK/INVcommon";
import "./CardTaskDetail.css";
import formatComma from "../react-lib/utils/formatComma";
import ModDocumentReason from "./Sub/ModDocumentReason";
import ModUpdateStatus from "./Sub/ModUpdateStatus";
import {
  DOC_NAME,
  POSITION_CODE,
  DOC_TYPE_TEXT_TH,
  STATUS_COLOR,
} from "../Common";
import { ProfileContext, AuthenContext } from "./MobContext";
import { ProvidedFilter } from "ag-grid-community";
import config from "../config/config";;

// TRK.Invoice:INFORM_SENDGOODS

export default (props: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModReason, setOpenModReason] = useState(false);
  const [openModUpdateStatus, setOpenModUpdateStatus] = useState(false);
  const [afterStatus, setAfterStatus] = useState<any>(null);
  const [isValidFlagForCamera, setIsValidFlagForCamera] = useState(false);

  const profile = useContext(ProfileContext);
  const authen = useContext(AuthenContext);

  const labelRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  console.log(" CardTaskDetail props: ", props);
  // Manual
  useEffect(() => {
    const patchManualAction = async () => {
      if (props.invoice.allowed_actions.length === 0) {
        return;
      }

      const [r, e, n] = await InvoiceDetail.patch({
        pk: props.invoice.id,
        apiToken: authen?.apiToken,
        data: {
          action: props.invoice.allowed_actions[0],
          debounce_key: `${props.invoice.id}_${props.invoice.edited}_${props.invoice.allowed_actions[0]}`
        },
      });
      if (e) {
        console.log(Error, e);
        console.log(n);
      } else {
        console.log(r);
        props.onEvent({ message: "updateTaskList" });
      }
    };

    if (props.invoice.all_document_scanned && profile?.position_code) {
      patchManualAction();
    }
  }, [props.invoice.all_document_scanned, profile?.position_code]);

  const handleEvent = async ({
    message,
    params,
  }: {
    message: string;
    params: any;
  }) => {
    console.log(message, params);
    if (message === "clickButton" && params.name === "invoiceUpdate") {
      const [r, e, n] = await InvoiceDetail.patch({
        apiToken: authen?.apiToken,
        pk: props.invoice.id,
        data: { action: params.value.action, loss_reason: params.value.reason },
      });
      if (e) {
        console.log(e);
        console.log(n);
      } else {
        console.log(r);
      }
      setOpenModal(false);
      props.onEvent({ message: "updateTaskList" });
    } else if (message === "openModal") {
      setOpenModal(true);
    } else if (message === "closeModal") {
      setOpenModal(false);
    } else {
      props.onEvent({ message, params });
    }
  };

  const OtherSevenDocument = ({ document }: any) => {
    console.log("SevenDocument", document);
    return (
      <>
        <h3 style={{ color: "#185070" }}>เอกสาร Invoice หมายเลขอื่นๆ</h3>
        {document &&
          document.length > 0 &&
          document
            .filter((item: any) => item.optional_seq)
            .map((doc: any, doc_index: number) => (
              <div
                key={doc_index}
                className="row"
                // style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    flexShrink: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >{`${doc.seq}. ${DOC_NAME[doc.seq]}`}</div>
                <div
                  className="status-label"
                  style={{
                    flexShrink: 0,
                    // textAlign: "right",
                    // color: "#F2994A",
                    // fontWeight: "bold",
                  }}
                >
                  {doc.status_label}
                </div>
              </div>
            ))}
      </>
    );
  };

  const SevenDocument = ({ document }: any) => {
    console.log("SevenDocument", document);
    return (
      <>
        {document &&
          document.length > 0 &&
          document
            .filter((item: any) => {
              // console.log("item filter SevenDocument");
              if (item && item.doc_type) {
                return (
                  (item.doc_type === "GENERAL" ||
                    item.doc_type === "CREDIT_NOTE" ||
                    item.doc_type === "DEBIT_NOTE") &&
                  item.show &&
                  !item.optional_seq
                );
              } else {
                return item.show;
              }
            })
            .map((doc: any, doc_index: number) => (
              <div key={doc_index} className="row">
                <div
                  style={{
                    flexShrink: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {doc.seq > 0 && doc.seq <= 7
                    ? `${doc.seq}. ${DOC_NAME[doc.seq]}`
                    : `1. ${DOC_TYPE_TEXT_TH[doc.doc_type]}`}
                </div>
                <div className="status-label" style={{ flexShrink: 0 }}>
                  {doc.status_label}
                </div>
              </div>
            ))}
      </>
    );
  };
  const OthersDocument = ({ document }: any) => {
    let filteredInvoice = document.filter((item: any) => {
      if (item && item.doc_type) {
        return (
          item.doc_type !== "GENERAL" &&
          item.doc_type !== "CREDIT_NOTE" &&
          item.doc_type !== "DEBIT_NOTE" &&
          item.show
        );
      } else {
        return false;
      }
    });

    if (filteredInvoice.length === 0) {
      return null;
    }

    // if (
    //   props.invoice.status === STATE_BACKEND.COLLECTING ||
    //   props.invoice.status === STATE_BACKEND.RE_COLLECTING ||
    //   props.invoice.status === STATE_BACKEND.DOCUMENT_RETURN
    // ) {
    var duplicate = 0;
    var filterDuplicate = 0;
    filteredInvoice.forEach((item: any) => {
      if (item.seq === 9 || item.seq === 10) {
        if (duplicate === 0) {
          duplicate = item.seq;
        } else {
          filterDuplicate = item.seq;
        }
      }
    });
    if (filterDuplicate !== 0) {
      filteredInvoice = filteredInvoice.filter(
        (item: any) => item.seq !== filterDuplicate
      );
    }
    // }

    return (
      <>
        <div className="doc-detail"> 
        <h3 style={{ color: "#185070" }}>เอกสารอื่นๆ</h3>
        </div> <div className="doc-detail-list">
        {filteredInvoice.map((doc: any, doc_index: number) => (
          <div
            key={doc_index}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>{`${DOC_TYPE_TEXT_TH[doc.doc_type]}`}</div>
            <div
              style={{
                textAlign: "right",
                color: "#F2994A",
                fontWeight: "bold",
              }}
            >
              {doc.status_label}
            </div>
          </div>
        ))}
        </div>
      </>
    );
  };

  const handleOpenModReason = () => {
    setOpenModReason(true);
  };

  const handleOpenModUpdateStatus = () => {
    // if(!props.invoice.all_document_scanned){}
    setOpenModUpdateStatus(true);
  };

  const handleScanDoc = () => {
    setOpenModUpdateStatus(false);
    const mobNative =
      typeof (window as any).MobNative !== "undefined"
        ? (window as any).MobNative
        : null;
    // console.log("typeof (window as any).MobNative", typeof (window as any).MobNative)
    console.log(
      "handleScanDoc props.invoice.related_documents",
      props.invoice.related_documents
    );
    console.log(
      "handleScanDoc props.invoice.related_doposition_codecuments",
      props.position_code
    );

    console.log(
      "handleScanDoc props.position_code === POSITION_CODE.BILLING || props.position_code === POSITION_CODE.SALE ",
      POSITION_CODE.BILLING.includes(props.position_code || "") ||
        POSITION_CODE.SALE.includes(props.position_code || "")
    );

    console.log(" handleScanDoc isNeedSignature: props", props);
    // console.log(" handleScanDoc isNeedSignature: 2mobNative", mobNative);

    if (mobNative) {
      // console.log(" handleScanDoc mobNative: ", mobNative);
      let filterDocId = props.invoice.related_documents
        .filter((item: any) => item.show)
        .map((item: any) => item.seq);

      let filterDocType = props.invoice.related_documents
        .filter((item: any) => item.show)
        .map((item: any) => item.doc_type);

      let isSaleBilling = false;
      if (
        POSITION_CODE.BILLING.includes(props.position_code || "") ||
        POSITION_CODE.SALE.includes(props.position_code || "")
      ) {
        isSaleBilling = true;
      }

      let isTransport = false;
      if (POSITION_CODE.TRANSPORT.includes(props.position_code)) {
        isTransport = true;
      }
      
      let isSale = false;
      if (POSITION_CODE.SALE.includes(props.position_code)) {
        isSale = true;
      }


      let appVersion = mobNative.getApplicationVersion().toString().split(".")
      // console.log(" handleScanDoc appVersion", mobNative.getApplicationVersion() )
      const majorVersion = Number(appVersion.shift())
      const minorVersion = Number(appVersion.pop())

      console.log(" handleScanDoc majorVersion, minorVersion: ", majorVersion, minorVersion);

      if (majorVersion >= 1 && minorVersion >= 45) {
        // New Version
        console.log(" handleScanDoc called setSubInvoice", [props.invoice]);
        mobNative.setSubInvoice(JSON.stringify([props.invoice]))
      } else {
        // Old Version
        console.log(" handleScanDoc called Old Version setInvoiceData", [props.invoice]);
        if ( !props.alreadySuccessSyncAllInvoice) {
          // Workaround When not Have Full Invoice
          mobNative.setInvoiceData(JSON.stringify([props.invoice]))
        }
      }

      console.log("Call launchScan")
      mobNative.launchScan(
        props.invoice.id,
        filterDocId,
        filterDocType,
        isSaleBilling,
        isTransport,
        isSale,
        config.API_HOST,
        isValidFlagForCamera
      );
    } else {
      console.log("Can't find mobNative ")
    }
  };

  const handleOnClickShootCamera = () => {
    // console.log("handleOnClickShootCamera props.invoice.related_documents ", props.invoice.related_documents)
    // let filterDocId = props.invoice.related_documents
    //   .filter((item: any) => item.show)
    //   .map((item: any) => item.seq);

    // console.log('handleOnClickShootCamera,filterDocId:', filterDocId)
    handleEvent({
      message: "clickButton",
      params: {
        name: "CaptureOtherDocs",
        value: props.invoice.id,
        isValid: isValidFlagForCamera,
        // docId: filterDocId,
      },
    });
  };

  useEffect(() => {
    const prepareDetail = async () => {
      console.log(
        "CardTaskDetail call change state by present props.invoice.status:",
        props.invoice.status,
        props.invoice
      );
      const [r1, e1, n1] = await CheckInvoiceChangeStateByPresentUser.retrieve({
        apiToken: authen?.apiToken,
        params: {
          id: props.invoice.id,
          status: props.invoice.status,
        },
      });
      if (e1) {
        console.log(
          "Error from  change state by present props.invoice",
          props.invoice
        );
        console.log(" Error from  change state by present e1: ", Error, e1);
        console.log("Error from  change state by present n1:", n1);
      } else {
        console.log("CardTaskDetail looking for previous status r1: ", r1);
        if (r1 && r1.is_valid) {
          console.log("CardTaskDetail changeStateis_valid: ", r1.is_valid);
          setIsValidFlagForCamera(r1.is_valid);
          const [
            r2,
            e2,
            n2,
          ] = await InvoiceDetailInvoiceRelatedDocumentFromStatusView.retrieve({
            pk: props.invoice.id,
            apiToken: authen?.apiToken,
          });
          if (e2) {
            console.log("Error from  Doc from status", e2);
            console.log("Error from  Doc from status", n2);
            console.log(Error, e2);
            console.log(n2);
          } else {
            console.log("CardTaskDetail Doc from status", r2);
            if (r2 && r2.related_documents) {
              setAfterStatus(r2);
            } else {
              setAfterStatus(null);
            }
          }
        } else {
          setAfterStatus(null);
        }
      }
    };
    prepareDetail();
  }, [props.invoice]);

  // console.log('middleStatus: ', setAfterStatus);

  /* 1. Normal View  เอกสารที่ต้องได้รับ is_valid = true
     2. Next View เอกสารที่นำเข้าระบบแล้ว is_valid = false
     3. Middle (Sale) เห็น พร้อมกัน 2 View COLLECTING, RE_COLLECTING
  */

  // General use only currentList
  if (afterStatus) {
    var currentList = afterStatus.related_documents;
  } else {
    currentList = props.invoice.related_documents;
  }

  // SaleTaskDetail use afterStatus, props.invoice.related_documents
  console.log(" CardTaskDetail currentList", currentList);
  console.log(" CardTaskDetail afterStatus", afterStatus);
  console.log(
    " CardTaskDetail props.invoice.related_documents",
    props.invoice.related_documents
  );

  const DoubleTaskDetail = ({ afterStatus, currentList }: any) => {
    console.log("DoubleTaskDetail profile: ", profile);
    console.log("DoubleTaskDetail afterStatus: ", afterStatus);
    console.log("DoubleTaskDetail currentList: ", currentList);

    return (
      <>
        {afterStatus && afterStatus.related_documents && (
          <>
            <div className="doc-detail">
              <h3>ผู้แทนรับเอกสารจาก Billing</h3>
              {afterStatus.related_documents.filter((item: any) => item.show)
                .length > 0 && (
                <Icon
                  name="info circle"
                  size="large"
                  inverted
                  onClick={() => {
                    props.goToInvoiceDetailInfo(afterStatus.related_documents);
                  }}
                />
              )}
            </div>
            <div className="doc-detail-list">
              <SevenDocument document={afterStatus.related_documents} />
            </div>
            { POSITION_CODE.SALE.includes(props.position_code || "") && 
              (<OthersDocument document={afterStatus.related_documents} />)
            }
          </>
        )}

        <div className="doc-detail">
          <h3>ผู้แทนส่งเอกสารคืน Billing</h3>
          {props.invoice.related_documents.filter((item: any) => item.show)
            .length > 0 && (
            <Icon
              name="info circle"
              size="large"
              inverted
              onClick={() => {
                props.goToInvoiceDetailInfo(props.invoice.related_documents);
              }}
            />
          )}
        </div>

        <div className="doc-detail-list">
          <SevenDocument document={props.invoice.related_documents} />
        </div>

        {props.invoice.related_documents.filter(
          (item: any) => item.optional_seq
        ).length > 0 && (
          <div className="doc-detail-list">
            <OtherSevenDocument document={props.invoice.related_documents} />
          </div>
        )}

        <OthersDocument document={props.invoice.related_documents} />
      </>
    );
  };

  const GeneralTaskDetail = ({ afterStatus, currentList }: any) => {
    console.log("currentList", currentList);
    return (
      <>
        <div className="doc-detail">
          {afterStatus ? (
            <h3>เอกสารที่นำเข้าระบบแล้ว</h3>
          ) : (
            <h3>เอกสารที่ต้องรับ</h3>
          )}
          {currentList.filter((item: any) => item.show).length > 0 && (
            <Icon
              name="info circle"
              size="large"
              inverted
              onClick={() => {
                props.goToInvoiceDetailInfo(currentList);
              }}
            />
          )}
        </div>

        <div className="doc-detail-list">
          <SevenDocument document={currentList} />
        </div>

        {currentList.filter((item: any) => item.optional_seq).length > 0 && (
          <div className="doc-detail-list">
            <OtherSevenDocument document={currentList} />
          </div>
        )}

        <OthersDocument document={currentList} />
      </>
    );
  };
  console.log("task detail", props)
  return (
    <div className="mob-task-detail">
      <TabLayout tab={props.tab} onEvent={handleEvent}>
        <Dimmer active={props.loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <ModDocumentReason
          open={openModReason}
          onClose={() => setOpenModReason(false)}
          apiToken={props.apiToken}
          documentList={afterStatus? afterStatus.related_documents: props.invoice.related_documents}
          invoice={props.invoice}
          onSuccess={() => {
            props.onEvent({ message: "updateTaskList" });
            setOpenModReason(false);
          }}
        />
        <div className="header">
          <div className="header-row">
            <div
              className="task-list-tab"
              onClick={(e) => {
                props.onEvent({
                  message: "clickTab",
                  params: {
                    name: "TaskList",
                  },
                });
              }}
            >
              <Icon name="angle left" />
            </div>
            <div
              className="serial"
              style={{ color: STATUS_COLOR[props.invoice.status] }}
            >
              {`${props.invoice.serial}  `}
            </div>
            &nbsp;&nbsp;
            <Button as="div" labelPosition="right">
              <Label
                className="btn-left-label"
                style={{
                  color: STATUS_COLOR[props.invoice.status],
                }}
                pointing="right"
              >
                Status
              </Label>
              <Label
                className="btn-right-label"
                style={{
                  backgroundColor: STATUS_COLOR[props.invoice.status],
                  border: `1px solid ${STATUS_COLOR[props.invoice.status]}`,
                }}
              >
                {props.invoice.status_label}
              </Label>
            </Button>
          </div>
          <div className="detail">
            <div className="detail-list">
              {TaskDetailFields.map((item, index) => {
                return (
                  <TaskDetailItem
                    key={index}
                    name={item.name}
                    value={
                      item.field === "amount" &&
                      Number(props.invoice[item.field])
                        ? `${formatComma(
                            Number(props.invoice[item.field])
                          )} บาท`
                        : props.invoice[item.field]
                    }
                  />
                );
              })}

              {/* COLLECTING, RE_COLLECTING with Sale */}
              {props.invoice.status === STATE_BACKEND.COLLECTING ||

              props.invoice.status === STATE_BACKEND.RE_COLLECTING ? (
                <DoubleTaskDetail
                  afterStatus={afterStatus}
                  currentList={currentList}
                />
              ) : (
                <GeneralTaskDetail
                  afterStatus={afterStatus}
                  currentList={currentList}
                />
              )}

              <div className="footer-btn-group">
                <div style={{ display: "flex", marginRight: "20px" }}>
                  <Button
                    fluid
                    // disabled={
                    //   props.invoice.all_document_scanned &&
                    //   profile?.position_code !== POSITION.SALE
                    // }
                    disabled={
                      props.readonly ||
                      (props.invoice.all_document_scanned &&
                        profile?.position_code !== POSITION.SALE) ||
                        ((props.invoice.status === STATE_BACKEND.SENTGOODS || 
                          props.invoice.status === STATE_BACKEND.TRANSPORTING)
                        && props.position_code.includes(POSITION.SALE, POSITION.MANAGER_SALE)
                        )
                    }
                    onClick={handleScanDoc}
                  >
                    สแกนเอกสาร
                  </Button>
                  <Button
                    fluid
                    disabled={props.readonly || 
                      ((props.invoice.status === STATE_BACKEND.SENTGOODS || 
                        props.invoice.status === STATE_BACKEND.TRANSPORTING)
                      && props.position_code.includes(POSITION.SALE, POSITION.MANAGER_SALE)
                      )}
                    className="inverted"
                    onClick={handleOpenModReason}
                  >
                    ระบุเหตุผลเพิ่มเติม
                  </Button>
                </div>
                <Button
                  compact
                  size="large"
                  className="icon"
                  disabled={props.readonly || 
                    ((props.invoice.status === STATE_BACKEND.SENTGOODS || 
                      props.invoice.status === STATE_BACKEND.TRANSPORTING)
                    && props.position_code.includes(POSITION.SALE, POSITION.MANAGER_SALE)
                    )}
                  onClick={handleOnClickShootCamera}
                >
                  <Icon name="camera" size="large" />
                  {/* ถ่ายรูปหลักฐาน */}
                </Button>
                <div>
                  <form>
                    <label
                      ref={labelRef}
                      className="custom-file-upload"
                      style={{ display: "none" }}
                    >
                      <input
                        ref={inputRef}
                        type="file"
                        name="image"
                        accept="image/*"
                        capture="environment"
                        onChange={(e) => {
                          console.log("TODO: Update image file");
                        }}
                      />
                    </label>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabLayout>
    </div>
  );
};

const TaskDetailFields = [
  { name: "วันที่ใบแจ้งหนี้", field: "date" },
  { name: "ชื่อลูกค้า", field: "customer_name" },
  { name: "สถานที่จัดส่ง", field: "delivery_address" },
  { name: "ยอดเงิน (บาท)", field: "amount" },
  { name: "พนักงานขาย", field: "sale_name" },
  { name: "ประเภท", field: "billing_type_label" },
  { name: "สถานะ", field: "status_label" },
  { name: "หมายเหตุ", field: "remark" },
];

const TaskDetailItem = (props: any) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "flex-start",
        borderBottom: "solid #ebebeb 1px",
        padding: "8px 0px",
        minHeight: "fit-content",
        flex: 1,
      }}
    >
      <div
        style={{
          flex: 1,
          paddingLeft: "4px",
          fontWeight: "bold",
          color: "#4e4e4e",
        }}
      >
        {props.name}
      </div>
      <div style={{ flex: "2 1 0%", paddingRight: "4px", color: "#4e4e4e" }}>
        {props.value}
      </div>
    </div>
  );
};

// const ManualEdit = (props: any) => {
//   const [reason, setReason] = useState("");
//   const [toAction, setToAction] = useState("");
//   let relevant_action: string[];
//   if (props.position_code && display_action.hasOwnProperty(props.position_code)) {
//     relevant_action = display_action[props.position_code];
//   } else {
//     if (!display_action.hasOwnProperty(props.position_code)) {
//       console.error(" Need Add ", props.position_code, " Into Common.ts ")
//     }
//     relevant_action = [];
//   }
//   relevant_action = relevant_action.filter(action =>
//     props.invoice.allowed_actions.includes(action)
//   );
//   return (
//     <Modal
//       open={props.openModal}
//       onClose={e => {
//         props.onEvent({
//           message: "closeModal",
//           params: {}
//         });
//       }}
//     >
//       <div style={{ display: "flex", flexDirection: "column", margin: "10px" }}>
//         {relevant_action.map((action: string, index: number) => (
//           <div
//             key={index}
//             style={{ display: "flex", margin: "10px", fontSize: "20px" }}
//             onClick={e => {
//               setToAction(action);
//             }}
//           >
//             <Radio
//               style={{ marginRight: "10px" }}
//               checked={toAction === action}
//             />
//             <div>{STATUS_FROM_ACTION[action]}</div>
//           </div>
//         ))}

//         {["DOCLOST_SENDGOODS", "DOCLOST_GETPAYMENT"].includes(toAction) && (
//           <div style={{ margin: "5px", fontSize: "20px" }}>
//             <div>เหตุผลในการสูญหาย</div>
//             <textarea
//               style={{ width: "100%" }}
//               rows={3}
//               value={reason}
//               onChange={e => {
//                 setReason(e.target.value);
//               }}
//             />
//           </div>
//         )}

//         <Button
//           color="blue"
//           onClick={e => {
//             props.onEvent({
//               message: "clickButton",
//               params: {
//                 name: "invoiceUpdate",
//                 value: {
//                   action: toAction,
//                   reason: reason
//                 }
//               }
//             });
//           }}
//         >
//           บันทึก
//         </Button>
//       </div>
//     </Modal>
//   );
// };
// let allowedActions = props.invoice.allowed_actions.filter((item: any) => {
//   if(display_action[profile?.position_code].includes(item) && !["INFO", "CONFIRM_GETPAYMENT", "INFO"].includes(item)) {
//     return item
//   }
// })

// if(allowedActions.length === 0){
//   return
// }
//       {/* <ManualEdit
//   position_code={props.position_code}
//   openModal={openModal}
//   onEvent={handleEvent}
//   invoice={props.invoice}
// /> */}
// {/* <ModUpdateStatus
//   open={openModUpdateStatus}
//   onClose={() => setOpenModUpdateStatus(false)}
//   handleScanDoc={handleScanDoc}
// /> */}
//   {/* First Section Collecting Only
//       {(props.invoice.status === STATE_BACKEND.COLLECTING ||
//         props.invoice.status === STATE_BACKEND.RE_COLLECTING) && (
//         <>
//           <div className="doc-detail">
//             <h3>ผู้แทนรับเอกสารจาก Billing</h3>
//             <Icon
//               name="info circle"
//               size="large"
//               inverted
//               onClick={props.goToPreviousInvoiceDetailInfo}
//             />
//           </div>
//           {afterStatus && afterStatus.related_documents && afterStatus.related_documents.length > 0 ? (
//             <div className="doc-detail-list">
//               {afterStatus.related_documents
//                 .filter(
//                   (item: any) => item.show
//                 )
//                 .map((doc: any, doc_index: number) => (
//                   <div key={doc_index} className="row">
//                     <div
//                       style={{
//                         flexShrink: 1,
//                         whiteSpace: "nowrap",
//                         overflow: "hidden",
//                         textOverflow: "ellipsis",
//                       }}
//                     >{`${doc.seq}. ${DOC_NAME[doc.seq]}`}</div>
//                     <div
//                       className="status-label"
//                       style={{ flexShrink: 0 }}
//                     >
//                       {doc.status_label}
//                     </div>
//                   </div>
//                 ))}
//             </div>
//           ) : null}
//         </>
//       )}
//                     */}

//                                       {/*
//       <Button fluid color="blue" onClick={ () => {
//         console.log('navigator',navigator)
//         console.log('navigator.getUserMedia',navigator.getUserMedia)
//         let constraints = { audio: true, video: true }
//         var promise = navigator.mediaDevices.getUserMedia(constraints);
//       }}>
//         ถ่ายรูปหลักฐาน
//       </Button> */}
