import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import invSetting from '../../../../../config/config';
import { Response, Error } from './common'

export interface coreUserSerializer {
    id?: any,
    password?: any,
    last_login?: any,
    is_superuser?: any,
    username?: any,
    first_name?: any,
    last_name?: any,
    is_staff?: any,
    is_active?: any,
    date_joined?: any,
    extra?: any,
    employee?: any,
    pre_name?: any,
    image?: any,
    email?: any,
    citizen_passport?: any,
    state?: any,
    full_name?: any,
    full_name_code?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'pk', 'full_name', 'role', 'name_username' */
/* data:  */
export const userscoreUserList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/users/apis/users/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface MSGFriendSerializer {
    id?: any,
    password?: any,
    last_login?: any,
    is_superuser?: any,
    username?: any,
    first_name?: any,
    last_name?: any,
    is_staff?: any,
    is_active?: any,
    date_joined?: any,
    extra?: any,
    employee?: any,
    pre_name?: any,
    image?: any,
    email?: any,
    citizen_passport?: any,
    state?: any,
    user_id?: any,
    friend_name?: any,
    is_already_friend?: any,
}


/* ['generics.ListAPIView'] */
/* params:  */
/* data:  */
export const MSGCanInviteList : 
{
  list:     ({chat_channel_id, params, apiToken, extra}:
             { chat_channel_id: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({chat_channel_id, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/MSG/chat-channel/(?P<chat_channel_id>.*)/can-invite/list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface MSGUserProfileUpdateSerializer {
    id?: any,
    password?: any,
    last_login?: any,
    is_superuser?: any,
    username?: any,
    first_name?: any,
    last_name?: any,
    is_staff?: any,
    is_active?: any,
    date_joined?: any,
    extra?: any,
    employee?: any,
    pre_name?: any,
    image?: any,
    email?: any,
    citizen_passport?: any,
    state?: any,
}


/* ['generics.UpdateAPIView'] */
/* params:  */
/* data:  */
export const MSGUserProfileView : 
{
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: MSGUserProfileUpdateSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: MSGUserProfileUpdateSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${invSetting.API_HOST}/apis/MSG/user/${pk}/profile/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${invSetting.API_HOST}/apis/MSG/user/${pk}/profile/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface TRKVerifyEmployeeSerializer {
    id?: any,
    password?: any,
    last_login?: any,
    is_superuser?: any,
    username?: any,
    first_name?: any,
    last_name?: any,
    is_staff?: any,
    is_active?: any,
    date_joined?: any,
    extra?: any,
    employee?: any,
    pre_name?: any,
    image?: any,
    email?: any,
    citizen_passport?: any,
    state?: any,
    api_token?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'username', 'employee_number' */
/* data:  */
export const TRKVerifyEmployee : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/verify-employee/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

