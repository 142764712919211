// For common type definitions, utilities, etc
import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import invSetting from '../../../../../config/config';


export interface Response<T> {
    ts_type: 'data',
    data: T,
}

export interface Error {
    ts_type: 'error',
    code?: string,
    message?: string,
    response?: any,
}

export interface EmployeeDeleteSerializer {
    inactive_reason: string,
    employee_id_list: number[],
    user_code: string,
}

export const EmployeeDelete: 
{
  update:   ({ params, data, apiToken, extra}:
             { params?: any,
                data?: EmployeeDeleteSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  update: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${invSetting.API_HOST}/users/api/employee/delete/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    // if(result[0]){
    //   return Promise.reject(result[0])
    // } else{
    //   return Promise.resolve(result[1])
    // }
    return [result[1], result[0], result[2]]
  }
}

export const RolesList: 
{
  retrieve:   ({ params, apiToken, extra}:
             { params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/users/api/roles-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  }
}

export const ExportExcel: 
{
  retrieve:   ({ params, apiToken, extra}:
             { params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/export-excel/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  }
}

export const Choices : 
{
  retrieve: ({ params, apiToken, extra}:
             {  params?: any,
                apiToken?: any,
                extra?: any,
            }) => any
} = 
{
  retrieve: async ({ params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/core/choices/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const Province : 
{
  retrieve: ({ params, apiToken, extra}:
             {  params?: any,
                apiToken?: any,
                extra?: any,
            }) => any
} = 
{
  retrieve: async ({ params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/core/province/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const EmployeeDetailFromCode : 
{
  retrieve: ({code, params, apiToken, extra}:
             { code: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({code, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/users/apis/users/employee/from-code/${code}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const InvoiceActionLogList : 
{
  retrieve: ({ params, apiToken, extra}:
             {  params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-action-log-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const invoiceRelatedDocumentTypeChoice : 
{
  retrieve: ({ params, apiToken, extra }:
      {  params?: any,
        apiToken?: any,
        extra?: any,
      }) => any
} = 
{
  retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-related-document-type-choice/`,
      { params: params ? { ...params } : {},
        ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const InvoiceDocumentImageList : 
{
  retrieve: ({ params, apiToken, extra }:
             {  params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({ params, data, apiToken, extra }:
             { 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-document-image-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${invSetting.API_HOST}/apis/TRK/document-image-list/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const RelatedReasonList : 
{
  retrieve: ({ params, apiToken, extra}:
             {  params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/related-reason-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const PositionList : 
{
  retrieve: ({ params, apiToken, extra}:
             {  params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/position-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
export interface InvoiceRelatedDocumentSerializer {
  id?: any,
  edit_user?: any,
  created?: any,
  edited?: any,
  status?: any,
  invoice?: any,
  doc_type?: any,
  seq?: any,
  signed_uploaded?: any,
  status_label?: any,
  action?: any,
  note?: any,
  reason?: any,
  lost?: any
}


/* ['generics.ListCreateAPIView'] */
/* params:  */
/* data:  */
export const InvoiceRelatedDocumentList : 
{
list:     ({params, apiToken, extra}:
           { 
              params?: any,
              apiToken?: any,
              extra?: any,
          }) => any,
create:   ({params, data, apiToken, extra}:
           { 
              params?: any,
              data?: InvoiceRelatedDocumentSerializer,
              apiToken?: any,
              extra?: any
          }) => any,
} = 
{
list: async ({params, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-related-list/`,
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
},

create: async ({params, data, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.post(`${invSetting.API_HOST}/apis/TRK/invoice-related-list/`,
    { ...data
    },
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
},
}

/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
export const InvoiceRelatedDocumentDetail : 
{
retrieve: ({pk, params, apiToken, extra}:
           { pk: any, 
              params?: any,
              apiToken?: any,
              extra?: any,
          }) => any,
update:   ({pk, params, data, apiToken, extra}:
           { pk: any, 
              params?: any,
              data?: InvoiceRelatedDocumentSerializer,
              apiToken?: any,
              extra?: any,
          }) => any,
patch:    ({pk, params, data, apiToken, extra}:
           { pk: any, 
              params?: any,
              data?: InvoiceRelatedDocumentSerializer,
              apiToken?: any,
              extra?: any,
          }) => any,
} = 
{
retrieve: async ({pk, params, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-related-detail/${pk}/`,
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
},

update: async ({pk, params, data, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.put(`${invSetting.API_HOST}/apis/TRK/invoice-related-detail/${pk}/`,
    { ...data
    },
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
},

patch: async ({pk, params, data, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.patch(`${invSetting.API_HOST}/apis/TRK/invoice-related-detail/${pk}/`,
    { ...data
    },
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
},
}

export const InvoiceDetailInvoiceRelatedDocumentFromStatusView : 
{
retrieve: ({pk, params, apiToken, extra}:
           { pk: any, 
              params?: any,
              apiToken?: any,
              extra?: any,
          }) => any
} = 
{
retrieve: async ({pk, params, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-related-from-status/${pk}/`,
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
}
}


export const CheckInvoiceChangeStateByPresentUser : 
{
retrieve: ({params, apiToken, extra}:
           { 
              params?: any,
              apiToken?: any,
              extra?: any,
          }) => any
} = 
{
retrieve: async ({params, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/invoice-check-change-status/`,
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
}
}


export const SignOut : 
{
get: ({params, apiToken, extra}:
           { 
              params?: any,
              apiToken?: any,
              extra?: any,
          }) => any
} = 
{
get: async ({params, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.get(`${invSetting.API_HOST}/users/api/devices/signout/`,
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
}
}

export const GetTotalInvoice : 
{
retrieve: ({params, apiToken, extra}:
           { 
              params?: any,
              apiToken?: any,
              extra?: any,
          }) => any
} = 
{
retrieve: async ({params, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/task-list-total/`,
    { params: params ? { ...params } : {},
       ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
}
}