import React from "react";
import { EmployeeSerializer } from "../react-lib/apis/issara/apps/TRK/INVEmployeeExtra";

export const ProfileContext = React.createContext<EmployeeSerializer | null>(
  null
);

export const ProfileProvider = ProfileContext.Provider;
export const ProfileConsumer = ProfileContext.Consumer;

export const AuthenContext = React.createContext<{
  apiToken?: string | null;
  login?: boolean | null;
} | null>(null);

export const AuthenProvider = AuthenContext.Provider;
export const AuthenConsumer = AuthenContext.Consumer;
