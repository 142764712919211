import React, { useContext } from "react";
import { ProfileContext, AuthenContext } from "./MobContext";
import { Button, Divider } from "semantic-ui-react";
import { withCookies } from "react-cookie";
import GrayLogger from "../react-lib/apps/common/GrayLoggerClass";
// import GrayLoggerModule from "../react-lib/apps/common/GrayLoggerModule";

const CardProfile = (props: any) => {
  const profile = useContext(ProfileContext);
  const authen = useContext(AuthenContext);

  return (
    <div className="mob-profile">
      <h2>ประวัติส่วนตัว</h2>
      <div className="info-segment">
        <Divider />
        <div className="row-wrapper">
          <div className="header">ชื่อ</div>
          <div className="info">
            {props.employeeInfo?.prename} &nbsp;{" "}
            {props.employeeInfo?.first_name}
          </div>
        </div>
        <Divider />
        <div className="row-wrapper">
          <div className="header">นามสกุล</div>
          <div className="info">{props.employeeInfo?.last_name}</div>
        </div>
        <Divider />
        <div className="row-wrapper">
          <div className="header">เบอร์โทรศัพท์</div>
          <div className="info">{props.employeeInfo?.phone_no}</div>
        </div>
        <Divider />
        <div className="row-wrapper">
          <div className="header">อีเมล</div>
          <div className="info">
            {props.employeeInfo?.email ? props.employeeInfo?.email : "-"}
          </div>
        </div>
        <Divider />
        <div className="row-wrapper">
          <div className="header">รหัสพนักงาน</div>
          <div className="info">{props.employeeInfo?.employee_no}</div>
        </div>
        <Divider />
        <div className="row-wrapper">
          <div className="header">ตำแหน่งงาน</div>
          <div className="info">{props.employeeInfo?.position}</div>
        </div>
        <Divider />
        <div className="row-wrapper">
          <div className="header">ทะเบียนรถ</div>
          <div className="info">
            {props.employeeInfo?.license_plate
              ? props.employeeInfo?.license_plate
              : "-"}
          </div>
        </div>
        <Divider />
        <div className="row-wrapper">
          <div className="header">ประเภทรถ</div>
          <div className="info">
            {props.employeeInfo?.car_type ? props.employeeInfo?.car_type : "-"}
          </div>
        </div>
        <Divider />
        <div className="row-wrapper">
          <div className="header">เส้นทางเดินรถ</div>
          <div className="info">
            {props.employeeInfo?.route ? props.employeeInfo?.route : "-"}
          </div>
        </div>
        <Divider />
      </div>
      <div className="btn-wrapper">
        <Button
          color="blue"
          onClick={(e) => {
            var log = new GrayLogger();
            log.debug("UserLogout");
            props.cookies.set("hostName", "");
            props.onEvent({
              message: "Logout",
              params: {},
            });
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default withCookies(React.memo(CardProfile));
