import React, {useState} from "react";
import { Icon, Dimmer, Loader, Button, Input} from "semantic-ui-react";
import ErrorMessage from "../react-lib/apps/common/ErrorMessage";
import { TRKVerifyEmployee } from "../../src/react-lib/apis/issara/apps/TRK/INVUser";

const CardVerifyUsername = (props: any) => {

  const [username, setUsername] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [checkInfoLoading, setCheckInfoLoading] = useState(false)
  const [checkInfoError, setCheckInfoError] = useState<any>(null);

  const handleCheckInfo = async () => {

    setCheckInfoLoading(true);
    const [response, error, network] = await TRKVerifyEmployee.list({
      params: {
        username: username,
        employee_number: employeeNumber
      }
    });
    setCheckInfoLoading(false);
    if (!error && response.items.length > 0) {
      console.log(response);
      props.onEvent({
        message: "clickButton",
        params: { name: "createPassword", apiToken: response.items[0].api_token }
      })
    } else {
      if (error.length > 200) {
        setCheckInfoError(
          `${network.response.status}: ${network.response.statusText}`
        );
      } else {
        setCheckInfoError(error);
      }
    }

  }

  return (
    <div style={{display: "flex", flexDirection:"column", alignItems: "center", height: "calc(100vh - 65px)"}}>

      <div
        style={{
          position: "absolute",
          left: 0,
          // top: "5px",
          height: "59px",
          width: "59px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "#247DAF",
          color: "white"
        }}
        onClick={e =>
          props.onEvent({
            message: "clickButton",
            params: { name: "goBack" }
          })
        }
      >
        <Icon size="large" name="angle left" />
      </div>

      <Dimmer active={props.loading} inverted>
        <Loader inverted />
      </Dimmer>
      <div style={{ 
        marginBottom: "20px",
        color: "white", 
        fontSize: "20px", 
        height: "59px", 
        backgroundColor: "#247DAF", 
        width: "100%",
        justifyContent:"center",
        alignItems: "center",
        display: "flex"}}>
        กรุณาระบุข้อมูลเพื่อยืนยันตัวตน
      </div>
      <ErrorMessage error={props.error} style={{ margin: 0 }} />
      <div style={{ width: "90%" }}>
        <div style={{ margin: "10px", color: "#666666", fontSize: "20px" }}>
          เบอร์โทรศัพท์<span style={{ color: "red" }}>*</span>:
        </div>
        <Input
          style={{ width: "100%" }}
          value={username}
          onChange={(e, v) => setUsername(v.value)}
        />
        <div style={{ margin: "10px", color: "#666666", fontSize: "20px" }}>
          รหัสพนักงาน<span style={{ color: "red" }}>*</span>:
        </div>
        <Input
          style={{ width: "100%" }}
          value={employeeNumber}
          onChange={(e, v) => setEmployeeNumber(v.value)}
        />
      </div>
      <div style={{paddingTop: "20px"}}> 

      <Button
        size="big"
        disabled={!username || !employeeNumber}
        style={{ backgroundColor: "#247DAF", color: "white" }}
        onClick={handleCheckInfo}
      >
        ตรวจสอบข้อมูล
      </Button>
      </div>
    </div>
  );
};

export default CardVerifyUsername;