export const optionsReducerInitial = {
  options: [],
  loading: false,
  error: null,
}

export const optionsReducer = (state: any, action: any) => {
  
  switch (action.type) {
    case "set":
      return { ...state, [action.key]: action.value };
    case "get":
      return { ...state, loading: true, error: null };
    case "success":
      return { ...state, loading: false, error: null, options: action.value };
    case "error":
      return { ...state, loading: false, error: action.value, options: [] };
  default:
      return state;
  }
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "set":
      return { ...state, ...action.newState };
    case "startCallApi":
      return { ...state, loading: true, error: null };
    case "finishLoading":
      return { ...state, loading: false };
    case "error":
      return {
        ...state,
        loading: false,
        error: action.error,
        dataList: []
      };
    default:
      return state;
  }
};