import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useMemo,
  SyntheticEvent,
} from "react";
import {
  Modal,
  Dropdown,
  Input,
  Button,
  Dimmer,
  Loader,
  Radio,
  RadioProps,
  Form,
} from "semantic-ui-react";
import {
  RelatedReasonList,
  InvoiceRelatedDocumentDetail,
} from "../../react-lib/apis/issara/apps/TRK/INVcommon";

import {
  InvoiceDetailCNDN
} from "../../react-lib/apis/issara/apps/TRK/INVInvoice"

import {
  optionsReducer,
  optionsReducerInitial,
} from "../../react-lib/utils/reducer";
import { DOC_TYPE_TEXT_TH, DOC_TYPE_TEXT, DOC_NAME } from "../../Common";
import ErrorMessage from "../../react-lib/apps/common/ErrorMessage";
import "./ModDocumentReason.css";

const ModDocumentReason = (props: any) => {
  const [reason, setReason] = useState<any>("");
  const [documentId, setDocumentId] = useState<any>(null);
  const [note, setNote] = useState("");
  const [documentOptions, setDocumentOptions] = useState<any>([]);
  const [reasonOptionsState, dispatchReasonOptions] = useReducer(
    optionsReducer,
    optionsReducerInitial
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const isMounted = useRef(true);

  const [radio, setRadio] = useState<string>("lost");

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.open) {
      handleGetReasonChoices();
    } else {
      clear();
    }
  }, [props.open]);

  useEffect(() => {
    if (props.documentList) {
      generateDocumentOptions();
    }
  }, [props.documentList]);

  const clear = () => {
    setReason("");
    setDocumentId(null);
    setNote("");
  };

  const generateDocumentOptions = () => {
    let options = [];
    for (let item of props.documentList) {
      if (!item.show) {
        continue;
      }
      if (item.doc_type === DOC_TYPE_TEXT.GENERAL) {
        options.push({
          id: item.id,
          value: item.id,
          text: `${item.seq}. ${DOC_NAME[item.seq]}`,
        });
      } else {
        options.push({
          id: item.id,
          value: item.id,
          text: DOC_TYPE_TEXT_TH[item.doc_type],
        });
      }
    }
    setDocumentOptions(options);
  };

  const handleGetReasonChoices = async () => {
    dispatchReasonOptions({ type: "get" });
    const [res, err, net] = await RelatedReasonList.retrieve({
      apiToken: props.apiToken,
    });
    if (isMounted.current) {
      if (res) {
        let newArr = res.items.map(
          (item: { id: number; value: string; name: string }) => ({
            key: item.id,
            value: item.value,
            text: item.name,
          })
        );
        dispatchReasonOptions({ type: "success", value: newArr });
      } else {
        dispatchReasonOptions({ type: "error", value: "error" });
      }
    }
  };

  const handleSaveReason = async () => {
    setIsLoading(true);
    setError(null);
    const [res, err, net] = await InvoiceRelatedDocumentDetail.patch({
      pk: documentId,
      apiToken: props.apiToken,
      data: {
        note,
        action: "INFO",
        reason,
        ...(radio === "lost" && {"lost": true})
      },
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        let status = props.invoice.status
        let doc = props.documentList.filter((item:any) => item.id === documentId)[0]
        if (props.invoice.is_cndn && (status === "COLLECTING" || status === "RE_COLLECTING" 
        || status === "DOCUMENT_RETURN" || status === "DOCUMENT_RECEIVED") && 
        (doc.doc_type === "CREDIT_NOTE" || doc.doc_type === "DEBIT_NOTE") && doc.lost){
          const [r, e, n] = await InvoiceDetailCNDN.patch({
            pk: props.invoice.id,
            apiToken: props.apiToken,
            data: {
              action: props.invoice.allowed_actions[0]
            }
          })
          if (r){
            props.onSuccess();
          }else {
            let errText = err;
            if (err.length > 200) {
              errText = `${net.response.status}: ${net.response.statusText}`;
            }
            setError(errText);
          }
        }
        else{
          props.onSuccess();
        }
      } else {
        let errText = err;
        if (err.length > 200) {
          errText = `${net.response.status}: ${net.response.statusText}`;
        }
        setError(errText);
      }
    }
  };

  const handleOnChangeRadio = (e: SyntheticEvent, { value }: RadioProps) => {
    setRadio(value as string);
  };

  

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className="mod-document-reason"
      closeOnDimmerClick={false}
    >
      <div className="content">
        <Dimmer.Dimmable>
          <Dimmer active={isLoading} inverted>
            <Loader inverted />
          </Dimmer>
          <ErrorMessage error={error} />
          <Form>
            <Form.Field>
              <Radio
                label="แจ้งเอกสารสูญหาย"
                name="radioGroup"
                value="lost"
                checked={radio === "lost"}
                onChange={handleOnChangeRadio}
              />
            </Form.Field>

            {radio === "lost" && (
              <>
                <Form.Field>
                  <ReasonField 
                    showReasonDropdown={false} 
                    documentOptions={documentOptions}
                    documentId={documentId}
                    setDocumentId={setDocumentId}
                    documentList={props.documentList}
                    setReason={setReason}
                    reason={reason}
                    setNote={setNote}
                    note={note}
                    reasonOptionsState={reasonOptionsState}
                  />
                </Form.Field>
              </>
            )}

            <Form.Field>
              <Radio
                label="แจ้งเหตุผลอื่นๆ (เอกสารไม่หาย)"
                name="radioGroup"
                value="reason"
                checked={radio === "reason"}
                onChange={handleOnChangeRadio}
              />
            </Form.Field>
            {radio === "reason" && (
              <Form.Field>
                <ReasonField 
                  showReasonDropdown={true} 
                  documentOptions={documentOptions}
                  documentId={documentId}
                  setDocumentId={setDocumentId}
                  documentList={props.documentList}
                  setReason={setReason}
                  reason={reason}
                  setNote={setNote}
                  note={note}
                  reasonOptionsState={reasonOptionsState}
                />
              </Form.Field>
            )}

            <Form.Field> 
              <div style={{ display: "flex" }}>
                <Button basic fluid content="ยกเลิก" onClick={props.onClose} />
                <Button fluid content="ยืนยัน" onClick={handleSaveReason} />
              </div>
            </Form.Field>
          </Form>
          <br />
        </Dimmer.Dimmable>
      </div>
    </Modal>
  );
};

const ReasonField = (props: any) => {
  return (
    <>
      <div className="row">
        <div className="label">เอกสาร</div>
        &ensp;
        <Dropdown
          options={props.documentOptions}
          selection
          value={props.documentId}
          onChange={(e, { value }) => {
            let document = props.documentList.find(
              (item: any) => item.id === value
            );
            if (document.reason) {
              props.setReason(document.reason);
            } else {
              props.setReason("");
            }
            if (document.note) {
              props.setNote(document.note);
            } else {
              props.setNote("");
            }
            props.setDocumentId(value);
          }}
        />
      </div>
      <br />
      {props.showReasonDropdown && (
        <>
          <div className="row">
            <div className="label">เหตุผล</div>
            &ensp;
            <Dropdown
              options={props.reasonOptionsState.options}
              selection
              error={!!props.reasonOptionsState.error}
              loading={props.reasonOptionsState.loading}
              value={props.reason}
              onChange={(e, { value }) => props.setReason(value)}
            />
          </div>
          <br />
        </>
      )}

      <div className="row">
        <div className="label">หมายเหตุ</div>
        &ensp;
        <Input value={props.note} onChange={(e) => props.setNote(e.target.value)} />
      </div>
      <br />
    </>
  );
};


ModDocumentReason.defaultProps = {
  open: false,
  onClose: () => {},
  documentList: [],
};

export default React.memo(ModDocumentReason);
