import base from './base.json';

var _settings: any = null;
const getSettings = () => {
  if(_settings !== null){
    return _settings
  }
  _settings = new Settings()
  return _settings
}

class Settings {
  constructor(
    domain = document.cookie && document.cookie
    .split('; ')
    //@ts-ignore
    .find(row => row.startsWith('hostName')) ? document.cookie
    .split('; ')
    .find(row => row.startsWith('hostName'))
    .split('=')[1] : ""
  ) {
    //@ts-ignore
    this.favor = this.selectDomain(domain);
    // url of api server
    //@ts-ignore
    this.API_HOST = this.favor.API_HOST || 'http://apis.invtracking.mor.company/';
    // company server
    //@ts-ignore
    this.COMPANY = this.favor.COMPANY || 'ANB';
    //@ts-ignore
    this.DEBUG = this.favor.DEBUG || true;
    //@ts-ignore
    this.PROXY = this.favor.PROXY || "";
    //@ts-ignore
    this.PROJECT = this.favor.PROJECT || "";
  }
  //@ts-ignore
  changeDomain(domain){
    //@ts-ignore
    this.favor = this.selectDomain(domain);
    //@ts-ignore
    this.API_HOST = this.favor.API_HOST
    //@ts-ignore
    this.COMPANY = this.favor.COMPANY
    //@ts-ignore
    this.DEBUG = this.favor.DEBUG
    //@ts-ignore
    this.PROXY = this.favor.PROXY ? this.favor.PROXY : ""
    //@ts-ignore
    this.PROJECT = this.favor.PROJECT || "";
  }
  //@ts-ignore
  selectDomain(domain) {
    if (domain in base) {
      //@ts-ignore
      return base[domain]
    } else if (!('default' in base)) {
      throw `cannot find "${domain}" or "default" in settings`
    }
    return base.default
  }
}

export default getSettings();