import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Icon,
  Dimmer,
  Loader,
  Pagination,
  PaginationProps,
  Transition,
  Modal,
  Image,
  Header,
} from "semantic-ui-react";
import TabLayout from "./TabLayout";
import { InvoiceSerializer } from "../react-lib/apis/issara/apps/TRK/INVInvoice";
import CardTaskDetail from "./CardTaskDetail";
import DateTextBox from "../react-lib/apps/common/DateTextBox";
import {
  dateToStringWithoutTimeBE,
  parseDate,
} from "../react-lib/utils/dateUtils";
import "./CardTaskList.scss";

import {
  STATUS,
  STATUS_COLOR,
  STATE_BACKEND,
  POSITION_CODE,
  COLUMN_TASK_LIST_STATE,
  COLUMN_TEXT_TASK_LIST,
} from "../Common";
import CardInvoiceDetailInfo from "./CardInvoiceDetailInfo";
import { ProfileContext, AuthenContext } from "./MobContext";
import { isValid } from "date-fns/esm";

import { limitPageSize } from "./Mob";
export default class Card extends React.Component<
  {
    tab: string;
    apiToken: string;
    position_code: string | null;
    invoices: InvoiceSerializer[];
    subInvoices: InvoiceSerializer[];
    onEvent: ({ message, params }: { message: string; params: any }) => void;
    filters: string;
    loading: boolean;
    startDate: Date;
    endDate: Date;
    statusList: any;
    getTotalInvoice: any;
    getIndividualInvoice: any;
    selectedGroupStatus: number;
    activePageList: number[];
    alreadySuccessSyncAllInvoice: boolean;
  },
  {
    sub: string;
    currentList: any[];
    total: number;
    statusList: any[];
    statusDict: any;
    selctedTopStatus: number;
    subInvoices: InvoiceSerializer[];
    selectedInvoice: InvoiceSerializer | null | undefined;
    internalLoading: boolean;
    visibleSearch: boolean;
  }
> {
  static contextType = ProfileContext;
  // static authenContextType = AuthenContext

  constructor(props: any) {
    super(props);

    this.state = {
      sub: "List",
      total: 0,
      currentList: [],
      statusList: [],
      statusDict: null,
      selctedTopStatus: 0,
      subInvoices: [],
      selectedInvoice: null,
      internalLoading: false,
      visibleSearch: false,
    };
    this.handleEvent.bind(this);
  }

  componentDidMount() {
    // console.log("CDM CardTaskList");
    // this.extractPropsInvoiceToState();
  }

  // componentDidUpdate(prevProps: any, prevState: any) {
  //   // console.log("CDU CardTaskList prevProps:", prevProps, "prevState:", prevState);
  //   if (prevProps.invoices !== this.props.invoices ) {
  //     // console.log("CDU CardTaskList not same");
  //     this.extractPropsInvoiceToState();
  //   }

  //   if (this.state.statusDict) {
  //     var prepareSubinvoices = this.state.statusDict[
  //       this.state.statusList[this.state.selctedTopStatus].key
  //     ].invoices;
  //   }

  //   if (prevProps.filters !== this.props.filters) {
  //     prepareSubinvoices = prepareSubinvoices.filter((item: any) => {
  //       return (
  //         item.customer_name.includes(this.props.filters) ||
  //         item.serial.includes(this.props.filters)
  //       );
  //     });
  //   }
  //   this.setState({subInvoices: prepareSubinvoices})
  // }

  componentDidUpdate(prevProps: any, prevState: any) {
    // console.log("CDU CardTaskList prevProps:", prevProps, "prevState:", prevState);
    // console.log("CDU CardTaskList this.props.filters: ", this.props.filters)
    // console.log("CDU CardTaskList this.state.selctedTopStatus: ", this.state.selctedTopStatus)
    // console.log("CDU CardTaskList this.state.statusList.length: ", this.state.statusList?.length)
    // console.log("CDU CardTaskList this.state.statusDict.length: ", this.state.statusDict?.length)

    if (prevProps.subInvoices !== this.props.subInvoices) {
      console.log(
        "CDU CardTaskList not same then Call Extract Props Invoice to State "
      );
      // TODO: check bug here !!!
      // this.extractPropsInvoiceToState();
      this.updateSelectedInvoice();


    } else {
      console.log("CDU CardTaskList are same");
    }

    // if ( (prevProps.filters !== this.props.filters) ||
    //  (prevState.statusDict !== this.state.statusDict) ||
    //  (prevState.statusList !== this.state.statusList) ||
    //  (prevState.selctedTopStatus !== this.state.selctedTopStatus) ) {

    //   let prepareSubinvoices = this.state.statusDict[
    //     this.state.statusList[this.state.selctedTopStatus].key
    //   ].invoices;

    //   prepareSubinvoices = prepareSubinvoices.filter((item: any) => {
    //     return (
    //       item.customer_name.includes(this.props.filters) ||
    //       item.serial.includes(this.props.filters)
    //     );
    //   });

    //   this.setState({subInvoices: prepareSubinvoices})

    // }
  }

  handleEvent = ({ message, params }: { message: string; params: any }) => {
    console.log(message, params);
    if (message === "clickTab") {
      if (params.name === "TaskList") {
        this.setState({ sub: "List", selectedInvoice: null });
      } else {
        this.props.onEvent({ message, params });
      }
    } else if (message === "pagination") {
      this.props.onEvent({ message, params });
    } else if (message === "choose" && params.name === "groupStatus") {
      this.props.onEvent({ message, params });
      // let length = params.value + 1;
      // if (
      //   Object.keys(this.props.statusList?.status).length >= length &&
      //   this.props.statusList?.status?.length >= length
      // ) {
      //   let prepareSubinvoices = this.state.statusDict[
      //     this.state.statusList[params.value].key
      //   ].invoices;

      //   prepareSubinvoices = prepareSubinvoices.filter((item: any) => {
      //     return (
      //       item.customer_name.includes(this.props.filters) ||
      //       item.serial.includes(this.props.filters)
      //     );
      //   });
      //   this.setState({
      //     selctedTopStatus: params.value,
      //     subInvoices: prepareSubinvoices,
      //   });
      // }
    } else if (message === "choose" && params.name === "Invoice") {
      const candidates = this.props.subInvoices.filter(
        (invoice) => invoice.id === params.value
      );
      this.setState({
        sub: "Lobby",
        selectedInvoice: candidates.length > 0 ? candidates[0] : null,
      });
    } else if (message === "updateTaskList") {
      // this.updateTaskList = true;
      this.props.onEvent({ message, params });
    } else {
      this.props.onEvent({ message, params });
    }
  };

  updateSelectedInvoice = () => {

    console.log("updateSelectedInvoice this.props.subInvoices", this.props)

    if (this.state.selectedInvoice && this.state.selectedInvoice.id && this.props.subInvoices) {
      console.log(
        " this.state.selectedInvoice && this.state.selectedInvoice.id "
      );

      const candidates = this.props.subInvoices.filter((invoice) => {
        if (typeof this.state.selectedInvoice?.id === "number") {
          return invoice.id === this.state.selectedInvoice.id;
        } else {
          return false;
        }
      });
    
      let prepareSelectedInvoice =
        candidates.length > 0 ? candidates[0] : null;

      var sub = "List";
      if (prepareSelectedInvoice) {
        sub = prepareSelectedInvoice ? "Lobby" : "List";
      }

      this.setState({
        selectedInvoice: prepareSelectedInvoice,
        sub: sub,
      })
    }
  }

  extractPropsInvoiceToState = () => {
    console.log("extractPropsInvoiceToState executed !!!!!!!!!! ");
    let positionKey: string = "";
    if (this.props.position_code) {
      positionKey = Object.keys(POSITION_CODE).find((key) =>
        POSITION_CODE[key].includes(this.props.position_code as string)
      ) as string;
    } else {
      console.error("Can't detect position_code ");
    }

    try {
      var total = 0; // use For TopMenu
      var statusList: any[] = []; // use For at Top Selected Menu [{key: "กำลังส่งสินค้า", label: "กำลังส่งสินค้า", count: 2}, {}]
      var prepareSubinvoices: InvoiceSerializer[] = []; // filtered invoice when user choose at top selcted menu

      console.log("check PositionKey", positionKey);

      console.log("check COLUMN_TASK_LIST_STATE[positionKey as string]: ");
      COLUMN_TASK_LIST_STATE[positionKey as string].forEach((item, idx) => {
        console.log(" idx:", idx);
        item.forEach((item2) => console.log("COLUMN_TASK_LIST_STATE:", item2));
      });

      console.log("check COLUMN_TEXT_TASK_LIST[positionKey as string]: ");
      COLUMN_TEXT_TASK_LIST[positionKey as string].forEach((item) => {
        console.log("COLUMN_TEXT_TASK_LIST", item);
      });

      const statusDict = this.getStatusDict(
        this.props.invoices,
        COLUMN_TASK_LIST_STATE[positionKey as string],
        COLUMN_TEXT_TASK_LIST[positionKey as string]
      ); // statusDict devide  Group of invoice

      console.log("CardTaskList statusDict", statusDict); // {รอรับเอกสาร: {name: "รอรับเอกสาร", count: 1, invoices: Array(1)}}
      // this.statusDict = statusDict;

      // DEBUG statusDict

      let statusDictKeys = Object.keys(statusDict);
      if (statusDictKeys?.length > 0) {
        console.log("StatusDict length > 0 ");
        statusDictKeys.forEach((item) => {
          console.log("Check StatusDict each keys", item);
          console.log(
            "Check StatusDict each keys have name",
            statusDict[item].name
          );
          console.log(
            "Check StatusDict each keys have count",
            statusDict[item].count
          );
          console.log(
            "Check StatusDict each keys have invoice.length",
            statusDict[item].invoices?.length
          );
        });
      }

      total = Object.keys(statusDict)
        .map((key) => statusDict[key].count)
        .reduce((a, b) => a + b, 0);

      console.log("statusList2", statusList);
      console.log("statusList2.length: ", statusList.length);

      Object.keys(statusDict).forEach((key) => {
        statusList.push({
          key: statusDict[key].name,
          label: statusDict[key].name,
          count: statusDict[key].count,
        });
      });

      console.log("statusList3: ", statusList);
      console.log("statusList3.length: ", statusList.length);

      if (statusList?.length > 0) {
        statusList.forEach((item) => {
          console.log(" statusList3 item.label", item.label);
        });
      }

      prepareSubinvoices = statusDict[statusList[0].key].invoices;

      // Search Feature
      prepareSubinvoices = prepareSubinvoices.filter((item) => {
        return (
          item.customer_name.includes(this.props.filters) ||
          item.serial.includes(this.props.filters)
        );
      });

      if (this.state.selectedInvoice && this.state.selectedInvoice.id) {
        console.log(
          " this.state.selectedInvoice && this.state.selectedInvoice.id "
        );

        const candidates = prepareSubinvoices.filter((invoice) => {
          if (typeof this.state.selectedInvoice?.id === "number") {
            return invoice.id === this.state.selectedInvoice.id;
          } else {
            return false;
          }
        });

        let prepareSelectedInvoice =
          candidates.length > 0 ? candidates[0] : null;
        if (!prepareSelectedInvoice) {
          const candidates2 = this.props.invoices.filter((invoice) => {
            if (typeof this.state.selectedInvoice?.id === "number") {
              return invoice.id === this.state.selectedInvoice.id;
            } else {
              return false;
            }
          });
          prepareSelectedInvoice =
            candidates2.length > 0 ? candidates2[0] : null;
        }

        var sub = "List";
        if (prepareSelectedInvoice) {
          sub = prepareSelectedInvoice ? "Lobby" : "List";
        }

        console.log("statusList4", statusList);
        console.log("prepareSubinvoices5", prepareSubinvoices);

        this.setState({
          selectedInvoice: prepareSelectedInvoice,
          sub: sub,
          total: total,
          statusList: statusList,
          statusDict: statusDict,
          subInvoices: prepareSubinvoices,
        });
      } else {
        console.log(
          "ELSE this.state.selectedInvoice && this.state.selectedInvoice.id "
        );
        this.setState({
          total: total,
          statusList: statusList,
          statusDict: statusDict,
          selctedTopStatus: 0,
          subInvoices: prepareSubinvoices,
        });
      }
    } catch {}
  };

  getStatusDict = (
    invoices: InvoiceSerializer[],
    combineStatus: string[][],
    statusName: string[]
  ) => {
    var statusDict: any = {};
    combineStatus.map((groupStatus, index) => {
      if (!(statusName[index] in statusDict)) {
        statusDict[statusName[index]] = {
          name: statusName[index],
          count: 0,
          invoices: [],
        };
      }

      groupStatus.forEach((eachStatus) => {
        for (const invoice of invoices) {
          if (eachStatus === invoice.status) {
            statusDict[statusName[index]].invoices.push(invoice);
            statusDict[statusName[index]].count += 1;
          }
        }
      });
    });
    return statusDict;
  };

  render() {
    let totalPages = 1;
    console.log("this.props.statusList? ", this.props.statusList);
    if (this.props.statusList?.status?.length > 0) {
      totalPages = Math.ceil(
        this.props.statusList?.status[this.props.selectedGroupStatus].count /
          limitPageSize
      );
    }
    
    const positionNoneDate = ['transport', 'sale']
    console.log("CardTaskList this.props", this.props)
    // console.log(" CardTaskList this.props.", this.props)
    // console.log("CardTaskList render this.state", this.state);
    // console.log("CardTaskList render this.props", this.props);
    // // console.log('render context this.employee', this.employee)
    // console.log('render context this.context', this.context)
    // console.log( 'CardTaskList this.props' ,this.props)

    return this.state.sub === "List" ? (
      <div style={{ height: "100%", minHeight: "100%" }}>
        <TabLayout tab={this.props.tab} onEvent={this.handleEvent}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100vh - 65px)",
            }}
          >
            <div
              style={{
                display: "flex",
                margin: "20px",
                color: "#00529C",
                fontSize: "25px",
                fontWeight: "bolder",
                justifyContent: "space-between",
              }}
            >
              <div>งานวันนี้</div>
              <div>
                <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                  {" "}
                  จำนวน&nbsp;&nbsp;&nbsp;
                </span>
                {` ${this.props.statusList?.total} `}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              {this.props.statusList?.status?.map(
                (status: any, index: number) => (
                  <>
                    <div
                      key={index}
                      style={{
                        flex: 1,
                        textAlign: "center",
                        borderLeft: "solid lightgray 1px",
                        ...(this.props.statusList?.status?.length ===
                          index + 1 && {
                          borderRight: "solid lightgray 1px",
                        }),
                        borderTop: "solid lightgray 1px",
                        borderBottom: "solid lightgray 1px",
                        fontSize: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: "5px",
                      }}
                      onClick={(e) => {
                        this.handleEvent({
                          message: "choose",
                          params: {
                            name: "groupStatus",
                            value: index,
                          },
                        });
                      }}
                    >
                      <span
                        style={{
                          color:
                            index === this.props.selectedGroupStatus
                              ? "#00529C"
                              : "grey",
                          fontSize: "16px",
                          fontWeight:
                            index === this.props.selectedGroupStatus
                              ? "bolder"
                              : "normal",
                        }}
                      >
                        {status.label}
                      </span>
                      <span
                        style={{
                          color:
                            index === this.props.selectedGroupStatus
                              ? "#00529C"
                              : "grey",
                          fontWeight: "bolder",
                        }}
                      >
                        {status.count}
                      </span>
                    </div>
                  </>
                )
              )}
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  padding: "4px",
                  width: "10%",
                  color: this.state.visibleSearch ? "#00529c" : "grey",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <Icon
                  size="large"
                  name="search"

                  onClick={() => {
                    if (this.state.visibleSearch) {
                      console.log(" Clear !!!!!! ")
                      this.handleEvent({
                        message: "inputChange",
                        params: {
                          name: "Search",
                          value: "",
                        },
                      });
                    }
                    this.setState({ visibleSearch: !this.state.visibleSearch });
                  }}
                />
              </div>

              <div style={{ padding: "4px 2px", width: "44%" }}>
                <DateTextBox
                  overlayClassNames="ctl-left"
                  style={{ width: "100%", display: positionNoneDate.includes(this.props.position_code)? "none": ""}}
                  inputStyle={{ width: "100%" }}
                  readOnlyInputProps={{readOnly: true}}
                  value={dateToStringWithoutTimeBE(this.props.startDate)}
                  onChange={(value) => {
                    let prepareDate = parseDate(value, false);
                    this.handleEvent({
                      message: "inputChange",
                      params: {
                        name: "startDate",
                        value: prepareDate,
                      },
                    });
                  }}
                
                />
              </div>
              <div style={{ padding: "4px 2px", width: "44%" }}>
                <DateTextBox
                  overlayClassNames="ctl-right"
                  style={{ width: "100%", display: positionNoneDate.includes(this.props.position_code)? "none": "" }}
                  inputStyle={{ width: "100%"}}
                  readOnlyInputProps={{readOnly: true}}
                  value={dateToStringWithoutTimeBE(this.props.endDate)}
                  onChange={(value) => {
                    let prepareDate = parseDate(value, false);
                    this.handleEvent({
                      message: "inputChange",
                      params: {
                        name: "endDate",
                        value: prepareDate,
                      },
                    });
                  }}
                  
                />
              </div>
            </div>

            <Transition.Group animation="drop" duration="300">
              {this.state.visibleSearch && (
                <>
                  <div style={{ margin: "4px 4px 4px 10px" }}>
                    <Input
                      value={this.props.filters}
                      icon
                      iconPosition="left"
                      onChange={(e, v) => {
                        this.handleEvent({
                          message: "inputChange",
                          params: {
                            name: "Search",
                            value: v.value,
                          },
                        });
                      }}
                      fluid
                      placeholder="ค้นหา"
                    >
                      <input />
                      <Icon name="search" />
                    </Input>
                  </div>
                </>
              )}
            </Transition.Group>

            <div
              style={{
                flex: 1,
                overflow: "auto",
                flexDirection: "column",
                alignSelf: "center",
                width: "95%",
              }}
            >
              {this.props.loading ? (
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              ) : null}
              {this.props.subInvoices?.length === 0 ? (
                <div
                  style={{
                    color: "#00529c",
                    textAlign: "center",
                    marginTop: "40px",
                    fontSize: "x-large",
                  }}
                >
                  {" "}
                  ไม่พบข้อมูล{" "}
                </div>
              ) : null}
              {this.props.subInvoices.map((item: any, index: number) => (
                <TaskItem
                  key={index}
                  item={item}
                  onClick={(e: any) => {
                    this.handleEvent({
                      message: "choose",
                      params: {
                        name: "Invoice",
                        value: item.id,
                      },
                    });
                  }}
                />
              ))}
            </div>
            {this.props.subInvoices?.length > 0 && totalPages > 1 ? (
              <div style={{ textAlign: "center", marginBottom: "4px" }}>
                <Pagination
                  disabled={!(this.props.subInvoices?.length > 0)}
                  onPageChange={(
                    e: React.MouseEvent<HTMLAnchorElement>,
                    data: PaginationProps
                  ) => {
                    this.handleEvent({
                      message: "pagination",
                      params: {
                        name: this.props.selectedGroupStatus,
                        value: data.activePage as number,
                      },
                    });
                  }}
                  activePage={
                    this.props.activePageList[this.props.selectedGroupStatus]
                  }
                  firstItem={null}
                  lastItem={null}
                  ellipsisItem={null}
                  pointing
                  secondary
                  siblingRange={1}
                  totalPages={totalPages}
                />
              </div>
            ) : null}
          </div>
        </TabLayout>
      </div>
    ) : this.state.sub === "Lobby" ? (
      <CardTaskDetail
        softReload={this.props.softReload}
        alreadySuccessSyncAllInvoice={this.props.alreadySuccessSyncAllInvoice}
        tab={this.props.tab}
        loading={this.props.loading}
        apiToken={this.props.apiToken}
        position_code={this.props.position_code}
        invoice={this.state.selectedInvoice}
        onEvent={this.handleEvent}
        goToInvoiceDetailInfo={(list: any[]) => {
          this.setState({ sub: "InvoiceDetailInfo", currentList: list });
        }}
        goToPreviousInvoiceDetailInfo={() => {
          this.setState({ sub: "InvoicePreviousDetailInfo" });
        }}
      />
    ) : this.state.sub === "InvoiceDetailInfo" ? (
      <CardInvoiceDetailInfo
        goBack={() => {
          this.setState({ sub: "Lobby" });
        }}
        invoice={this.state.selectedInvoice}
        currentList={this.state.currentList}
      />
    ) : this.state.sub === "InvoicePreviousDetailInfo" ? (
      <CardInvoiceDetailInfo
        goBack={() => {
          this.setState({ sub: "Lobby" });
        }}
        invoice={this.state.selectedInvoice}
      />
    ) : (
      <></>
    );
  }
}

const TaskItem = (props: any) => {
  // console.log("TaskItem props:", props)
  return (
    <div
      style={{
        height: "fit-content",
        minHeight: "75px",
        width: "100%",
        paddingTop: "15px",
        paddingBottom: "15px",
        display: "flex",
        borderBottom: "solid lightgray 0.5px",
        color: STATUS_COLOR[props.item.status],
      }}
      onClick={(e) => {
        props.onClick(e);
      }}
    >
      <div
        style={{
          flex: 3,
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <Icon name="circle" size="big" />
      </div>
      <div style={{ flex: 10 }}>
        <div style={{ fontWeight: "bolder", fontSize: "medium" }}>
          {props.item.serial}
        </div>
        <div style={{ fontWeight: "bold" }}>{props.item.customer_name}</div>
        <div>{props.item.billing_type_label}</div>
      </div>
      <div
        style={{
          flex: 5,
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>{props.item.status_label}</div>
        <div style={{ color: "#4C4C4C" }}>
          {props.item.time_tracking && "ถึงประมาณ " + props.item.time_tracking}
        </div>
      </div>
    </div>
  );
};
