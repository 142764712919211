export const STATUS: any = {
  CREATED: "รอส่งสินค้า",
  TRANSPORTING: "กำลังส่งสินค้า",
  SENTGOODS: "ส่งสินค้าแล้ว มีเอกสาร",
  CONFIRMED_SENTGOODS: "ธุรการขนส่งได้รับเอกสารจากพนักงานขนส่ง",
  READY_TO_COLLECT: "Billing ได้รับเอกสารจากธุรการขนส่ง",
  COLLECTING: "ผู้แทน รับเอกสารจาก Billing",
  DOCUMENT_RETURN: "ผู้แทน ส่งเอกสารคืน Billing",
  DOCUMENT_RECEIVED: "พนักงาน Billing Scan รับเอกสารคืนจากผู้แทน",
  CHECK_GOTPAYMENT: "รอตรวจสอบการชำระเงิน",
  CONFIRMED_GOTPAYMENT: "ชำระเงินแล้ว",
  GOODS_RETURN: "คืนสินค้า (มีการทำ CN)",
  GET_ACCOUNT: "Billing รับเอกสารจากบัญชี"
};

export const STATUS_COLOR: any = {
  CREATED: "#4F4F4F",
  TRANSPORTING: "#005290",
  SENTGOODS: "#219653",
  CONFIRMED_SENTGOODS: "#7D7B0E",
  READY_TO_COLLECT: "#247DAF",
  COLLECTING: "#56CCF2",
  RE_COLLECTING: "#56CCF2",
  DOCUMENT_RETURN: "#79C000",
  DOCUMENT_RECEIVED: "#906CCB",
  CHECK_GOTPAYMENT: "#185070",
  CONFIRMED_GOTPAYMENT: "#4F4F4F",
  GOODS_RETURN: "blue",
  GET_ACCOUNT: "orange"
};

// export const STATUS_COLOR: any = {
//     'CREATED': '#BD6D02',
//     'TRANSPORTING': '#185070',
//     'SENTGOODS': '#F2994A',
//     'CONFIRMED_SENTGOODS': '#7D7B0E',
//     'READY_TO_COLLECT': '#BDBA1E',
//     'COLLECTING': "#70B4FF",
//     'DOCUMENT_RETURN': '#702370',
//     'DOCUMENT_RECEIVED': '#127060',
//     'CHECK_GOTPAYMENT': '#DB4632',
//     'CONFIRMED_GOTPAYMENT':  '#219653',
//     'GOODS_RETURN': 'blue'
// }

export const STATUS_FROM_ACTION: any = {
  CREATE: "รอส่งสินค้า",
  TRANSPORT: "กำลังส่งสินค้า",
  SENDGOODS: "ส่งสินค้าแล้ว มีเอกสาร",
  CONFIRM_SENDGOODS: "ธุรการขนส่งได้รับเอกสารจากพนักงานขนส่ง",
  COLLECT_READY: "Billing ได้รับเอกสารจากธุรการขนส่ง",
  COLLECT: "ผู้แทน รับเอกสารจาก Billing",
  COLLECT_RETURN: "ผู้แทน ส่งเอกสารคืน Billing",
  RE_COLLECTING: 'ผู้แทน รับเอกสารจาก Billing ใหม่',
  COLLECT_READY_AGAIN: "พนักงาน Billing Scan รับเอกสารคืนจากผู้แทน",
  GETPAYMENT: "รอตรวจสอบการชำระเงิน",
  CONFIRM_GETPAYMENT: "ชำระเงินแล้ว",
  RETURN_GOOD: "คืนสินค้า (มีการทำ CN)",
  GET_ACCOUNT: "Billing รับเอกสารจากบัญชี"
};

export const NEED_REASON_ACTION = ["DOCLOST_SENDGOODS"];

export const InvBlue = "#00529C";

export const DOC_NAME_ALL : { [key: string]: string } = {
  1: "1. ต้นฉบับใบเสร็จรับเงิน",
  2: "2. สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  3: "3. ต้นฉบับใบแจ้งหนี้",
  4: "4. สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  5: "5. ต้นฉบับใบกำกับภาษี/ใบส่งสินค้า",
  6: "6. สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  7: "7. สำเนาใบเสร็จรับเงิน",
  8: 'เอกสารการชำระเงิน',
  9: 'ใบวางบิล',
  10: 'ใบวางบิล'
};

export const OTHER_DOC_NAME_ALL : { [key: string]: string }= { 
  PURCHASE: "ใบสั่งซื้อ",
  RECEIVE: "ใบรับสินค้า",
  OUTSOURCE: "ใบขนส่ง",
  BILL: "ใบวางบิล",
  PAYMENT: "เอกสารชำระเงิน",
  TRANSPORT: "ใบนำส่งสินค้า",
  CREDIT_NOTE: "ใบลดหนี้",
  DEBIT_NOTE: "ใบเพิ่มหนี้",
  OTHER: "เอกสารอื่นๆ",
}

export const MAPDOCTYPE : { [key: string]: number } = {
  PURCHASE: 1,
  RECEIVE: 2,
  OUTSOURCE: 4,
  TRANSPORT: 5,
  OTHER: 7,
};

export const DOC_NAME: { [key: string]: string } = {
  1: "ต้นฉบับใบเสร็จรับเงิน",
  2: "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  3: "ต้นฉบับใบแจ้งหนี้",
  4: "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  5: "ต้นฉบับใบกำกับภาษี/ใบส่งสินค้า",
  6: "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  7: "สำเนาใบเสร็จรับเงิน",
};

export const DOC_NAME_OTHER: {[key: string]: string } = {
  8: 'เอกสารการชำระเงิน',
  9: 'ใบวางบิล กรณีได้รับการชำระเงิน',
  10: 'ใบวางบิล กรณีไม่ได้รับการชำระเงิน'
};

export const DOC_TYPE_TEXT: { [key: string]: string } = {
  GENERAL: "GENERAL",
  PURCHASE: "PURCHASE", // ใบสั่งซื้อ
  RECEIVE: "RECEIVE", // ใบรับสินค้า
  BILL: "BILL", // ใบวางบิล
  OUTSOURCE: "OUTSOURCE", // ใบขนส่ง
  TRANSPORT: "TRANSPORT", // ใบนำส่งสินค้า
  PAYMENT: "PAYMENT", // เอกสารชำระเงิน
  CREDIT_NOTE: "CREDIT_NOTE",
  DEBIT_NOTE: "DEBIT_NOTE",
  OTHER: "OTHER", // อื่นๆ
};

export const DOC_TYPE_TEXT_TH: { [key: string]: string } = {
  GENERAL: "GENERAL",
  PURCHASE: "ใบสั่งซื้อ", // ใบสั่งซื้อ
  RECEIVE: "ใบรับสินค้า", // ใบรับสินค้า
  BILL: "ใบวางบิล", // ใบวางบิล
  OUTSOURCE: "ใบขนส่ง", // ใบขนส่ง
  TRANSPORT: "ใบนำส่งสินค้า", // ใบนำส่งสินค้า
  PAYMENT: "เอกสารชำระเงิน", // เอกสารชำระเงิน
  OTHER: "อื่นๆ", // อื่นๆ
  CREDIT_NOTE: "เอกสาร Credit Note", 
  DEBIT_NOTE: "เอกสาร Debit Note"
};

export const POSITION: { [key: string]: string } = {
  SALE: "sale",
  MANAGER_SALE: "mana_sale"
};

export const STATE_BACKEND: { [key: string]: string } = { 
  CREATED : "CREATED", //'รอส่งสินค้า'
  TRANSPORTING : "TRANSPORTING", //'กำลังส่งสินค้า'
  SENTGOODS : "SENTGOODS", //'ส่งสินค้าแล้ว'
  CONFIRMED_SENTGOODS : "CONFIRMED_SENTGOODS", //'ธุรการขนส่งได้รับเอกสารจากพนักงานขนส่ง'
  READY_TO_COLLECT : "READY_TO_COLLECT", //'Billing ได้รับเอกสารจากธุรการขนส่ง'
  COLLECTING : "COLLECTING", //"ผู้แทน รับเอกสารจาก Billing" //# RE_COLLECTING: Only BILLING_TYPE.AFTER, BillStatus.CANNOT_COLLECT_CAN_BILL
  RE_COLLECTING : "RE_COLLECTING", //"ผู้แทน รับเอกสารจาก Billing ใหม่"
  DOCUMENT_RETURN : "DOCUMENT_RETURN", //'ผู้แทน ส่งเอกสารคืน Billing'
  DOCUMENT_RECEIVED : "DOCUMENT_RECEIVED", //'พนักงาน Billing Scan รับเอกสารคืนจากผู้แทน'
  CHECK_GOTPAYMENT : "CHECK_GOTPAYMENT", //'รอตรวจสอบการชำระเงิน'
  CONFIRMED_GOTPAYMENT : "CONFIRMED_GOTPAYMENT", //'ชำระเงินแล้ว'
  GOODS_RETURN : "GOODS_RETURN", //'คืนสินค้า (มีการทำ CN)',
  GET_ACCOUNT: "GET_ACCOUNT",
  CANCELLED: "CANCELLED",
}

export const POSITION_CODE: { [key: string]: string[] } = { 
  OFFICETRAN : ["officetran"], //'ธุรการขนส่ง'
  BILLING: ["mana_bill", "billing2", "billing" ], //'Billing'
  TRANSPORT: ["mana_trans", "head_trans", "transport" ], //'ขนส่ง'
  SALE: ["sale", "mana_sale" ], //'ตัวแทน'
  OTHER: ["manager","acc_fin", "admin"] // Other
}

export const COLUMN_TEXT_TASK_LIST:  {[key: string]: string[]} = {
  TRANSPORT: ["กำลังส่งสินค้า", "ส่งสินค้าแล้ว"], //'ขนส่ง'
  OFFICETRAN : ["กำลังส่งสินค้า", "ส่งสินค้าแล้ว"], 
  BILLING: ["รอรับเอกสาร", "ส่งให้ผู้แทน / ถ่ายรูปหลักฐาน", "รอตรวจสอบการชำระเงิน"] , //'Billing'
  SALE: ["การจัดส่งสินค้า", "รอรับเอกสาร", "วางบิล เก็บเงิน", "ส่งคืนเอกสาร"], //'ตัวแทน'
  OTHER: ["ทุกสถานะ"] // Other
}

export const COLUMN_TASK_LIST_STATE:  {[key: string]: string[][]} = {

  TRANSPORT: [
    [STATE_BACKEND.TRANSPORTING], 
    [STATE_BACKEND.SENTGOODS]],

  OFFICETRAN: [
    [STATE_BACKEND.TRANSPORTING], 
    [STATE_BACKEND.SENTGOODS]],

  BILLING: [
    [STATE_BACKEND.SENTGOODS, STATE_BACKEND.DOCUMENT_RETURN, STATE_BACKEND.CREATED], 
    [STATE_BACKEND.READY_TO_COLLECT, STATE_BACKEND.DOCUMENT_RECEIVED,  STATE_BACKEND.GET_ACCOUNT], 
    [STATE_BACKEND.CHECK_GOTPAYMENT]],

  SALE:[
    [STATE_BACKEND.TRANSPORTING, STATE_BACKEND.SENTGOODS],
    [STATE_BACKEND.READY_TO_COLLECT, STATE_BACKEND.DOCUMENT_RECEIVED, STATE_BACKEND.GET_ACCOUNT], 
    [STATE_BACKEND.COLLECTING, STATE_BACKEND.RE_COLLECTING],
    [STATE_BACKEND.DOCUMENT_RETURN]], 
  OTHER1: [[]] // Other
}