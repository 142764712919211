import React from "react";
import { withCookies } from "react-cookie";
import { Icon } from "semantic-ui-react";
import './SubCompany.css';

const MPC = "/static/images/logo_mpc.png";
const ANB ="/static/images/logo_anb.png";

const Sub = (props: any) => {
  const children = React.Children.toArray(props.children);
  return (
    <div className="company">
      <span className="content-header">กรุณาเลือกบริษัทก่อนลงชื่อเข้าใช้งาน</span>
      <div className="item" onClick={() => {
          props.cookies.set("hostName", "MPC")
          props.onHostnameChange("MPC")
          // window.location.hostname = config.MPC
          props.onEvent({ message: "clickButton", params: { name: "continue" } })
      }}>
        <div>
            <img style={{ height: "75px" }} src={process.env.PUBLIC_URL + MPC} />
        </div>
        <div className="content">บริษัท สหแพทย์เภสัช จำกัด</div>
        <Icon name="angle right" className="company-icon" />
      </div>
      <hr />
      <div className="item"
      onClick={() => {
        props.cookies.set("hostName", "ANB")
        props.onHostnameChange("ANB")
        // window.location.hostname = config.ANB
        props.onEvent({ message: "clickButton", params: { name: "continue" } })}}
      >
        <div>
            <img style={{ height: "100px" }} src={process.env.PUBLIC_URL + ANB} />
        </div>
        <div className="content">บริษัท เอ.เอ็น.บี <br/>ลาบอราตอรี่ <br/>(อำนวยเภสัช) จำกัด</div>
        <Icon name="angle right" className="company-icon"/>
      </div>
    </div>
  );
};

export default withCookies(Sub);
