import React, { useState, useEffect, useRef } from "react";
import { Button, Segment, Icon, Modal, Image } from "semantic-ui-react";
import { STATUS, STATUS_FROM_ACTION, POSITION, STATE_BACKEND, DOC_TYPE_TEXT, DOC_TYPE_TEXT_TH, DOC_NAME } from "../Common";
import Iframe from "react-lib/apps/common/CustomIframe";
import "./CardInvoiceDetailInfo.css";

const CardInvoiceDetailInfo = (props: any) => {
  const [image, setImage] = useState("");
  const [openModImage, setOpenModImage] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<any>(null)
  const [openModReasonNote, setOpenModReasonNote] = useState(false)
  const iframeRef = useRef<any>(null);

  const checkUndefined = (value: any) => {
    if (value) {
      return value;
    }
    return "";
  };

  const createStatusInfo = () => {
    if (!props.invoice.related_documents) {
      return null;
    }
    if (props.invoice.related_documents.length === 0) {
      return (
        <div className="no-doc">
          <h1>ไม่มีเอกสารที่ต้องรับ</h1>
        </div>
      );
    }
    return props.invoice.related_documents
      .map((item: any) => {
        let text = "";
        if (item.doc_type === DOC_TYPE_TEXT.GENERAL) {
          text = `${item.seq}. ${DOC_NAME[item.seq]}`;
        } else {
          text = `${DOC_TYPE_TEXT_TH[item.doc_type]}`;
        }
        return (
          <div className="detail-row" key={item.id} onClick={() => {
            if(item.reason || item.note){
              setSelectedDoc(item)
              setOpenModReasonNote(true)
            }
          }}>
            <b style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{text}</b>
            <b className="status">{item.holder_label}{(item.reason || item.note) && <Icon name="exclamation circle" color="red"/>}</b>
          </div>
        );
      });
  };

  const ScanList = ({document}: any) => {

    if (!document) {
      return null;
    }

    var filteredInvoice = document.filter((item: any) => item.show)

    if (props.invoice.status === STATE_BACKEND.COLLECTING || props.invoice.status === STATE_BACKEND.RE_COLLECTING) {
      var duplicate9 = 0;
      var duplicate10 = 0;

      filteredInvoice.forEach((item:any, index:number) => {
        if (item.seq === 9) { 
          duplicate9 = index
        } else if (item.seq === 10) {
          duplicate10 = index
        }
        if (duplicate9 !== 0 && duplicate10 !== 0) {
          // ไม่ระบุ ทั้งคู่ แสดง อันเดียว
          if (filteredInvoice[duplicate9].image === null && filteredInvoice[duplicate10].image === null ) {
            filteredInvoice =  filteredInvoice.filter((item:any) => item.seq !== 10 )
          } else {
            // ถ้ามี อันใด อันหนึ่ง ที่มีเอกสาร แสดงอันนั้นอันเดียว
            if (filteredInvoice[duplicate9].image !== null) {
              filteredInvoice = filteredInvoice.filter((item:any) => item.seq !== 10 )
            } else if (filteredInvoice[duplicate10].image !== null ) {
              filteredInvoice = filteredInvoice.filter((item:any) => item.seq !== 9 )
            }
          }
        }
      });
    }
    
    if (filteredInvoice.length === 0) {
      return null
    }

    return filteredInvoice
      .map((item: any) => {
        let text = "";
        if (item.doc_type === DOC_TYPE_TEXT.GENERAL) {
          text = `${item.seq}. ${DOC_NAME[item.seq]}`;
        } else {
          text = `${DOC_TYPE_TEXT_TH[item.doc_type]}`;
        }
        return (
          <div className="scan-list-row">
            <b style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{text}</b>
            {item.image_path ? (
              <Button
                compact
                size="mini"
                content="ดูรูปเอกสาร"
                onClick={() =>
                  handleViewImage(item.image_path)
                }
              />
            ): (<div className="scan-list-detail"> ไม่มีข้อมูล </div>)} 
          </div>
        );
      });
  };

  const handleViewImage = (img: string) => {
    setImage(img);
    setOpenModImage(true);
  };

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.iframeRef) {
      let img = iframeRef.current.iframeRef.contentDocument.getElementsByTagName(
        "img"
      )[0];
      if (img) {
        img.style.width = "100%";
        img.style.height = "auto";
      }
    }
  }, [iframeRef.current]);

  return (
    <div className="mob-invoice-detail-info">
      <Modal
        open={openModImage}
        onClose={() => {
          setImage("");
          setOpenModImage(false);
        }}
      >
        <Segment color="grey" inverted className="mob-invoice-detail-info-mod" style={{ display: "contents" }}>
          <Image src={image}/>
          {/* <iframe src={image} style={{ width: "100%", height: "100%" }} ref={iframeRef}>
           {image && <img src={image} />}
          </iframe> */}
        </Segment>
      </Modal>
      <Modal open={openModReasonNote} closeOnDimmerClick onClose={() => {
        setSelectedDoc(null)
        setOpenModReasonNote(false)
      }} >
          <div style={{ padding: "20px", lineHeight: "1.7em", wordBreak: "break-all" }}>
            <h4>เหตุผล:</h4>
            <p>{selectedDoc?.reason}</p>
            <h4>หมายเหตุ:</h4>
            <p>{selectedDoc?.note}</p>
          </div>
      </Modal>
      <div className="header">
        <div className="header-icon" onClick={props.goBack}>
          <Icon name="chevron left" size="big" />
        </div>
        <div className="info-detail">
          <h2>รายละเอียดเอกสาร</h2>
          <h3 className="info-invoice-no">{`Invoice No: ${checkUndefined(props.invoice.serial)}`}</h3>
        </div>
      </div>
      <div className="status-info">{createStatusInfo()}</div>
      <div className="scan-list">
        <h3>รายการเอกสารสแกน / รูปถ่าย</h3>
        <ScanList document={props.currentList}/>
      </div>
    </div>
  );
};

CardInvoiceDetailInfo.defaultProps = {
  goBack: () => {},
  invoice: {},
  currentList: [],
};

export default React.memo(CardInvoiceDetailInfo);
